import { useNavigate } from 'react-router-dom';

import useUnreadAppNotifications from '../../hooks/useUnreadAppNotifications';
import db from '../../index-db';

import { useState } from 'react';
import PopupModal from '../../components/PopupModal';
import Button from '../../components/Button';
import { useApi } from '../../acfs-apis/dwar-api-provider';
import { NOTIFICATION_SYNC_STATUS } from '../../constants';
import useLogging from '../../hooks/useLogging';
import { LogType } from '../../enums/LogType';

const NotificationPopups = () => {
  const notificationData = useUnreadAppNotifications();
  const navigate = useNavigate();
  const api = useApi();
  const logging = useLogging();
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(true);
  const [currentActiveIndex, setCurrentIndex] = useState(0);
  if (!notificationData?.length) {
    return <></>;
  }
  const isNotificationSyncDone =
    localStorage.getItem(NOTIFICATION_SYNC_STATUS) === 'true';
  const currentItemId = notificationData[currentActiveIndex].id;
  const onButtonClick = (id: string) => async () => {
    setIsMessageModalOpen(false);
    const lastActionTaken = new Date().valueOf();

    if (isNotificationSyncDone) {
      api
        .saveSingleNotification({
          isNotificationRead: true,
          lastActionTakenSync: `${lastActionTaken}`,
          notificationId: id,
        })
        .catch((e) => {
          db.failedNotifications.add({
            isNotificationRead: 1,
            lastActionTakenSync: `${lastActionTaken}`,
            notificationId: id,
          });
          logging(
            'warn',
            'Unable to send single notification post request to backend',
            e,
            true,
            [
              {
                key: LogType.Home,
                value: `/dwar/api/acfs/Notification/saveNotification`,
              },
            ],
            false
          );
        });
    }
    db.notifications.update(id, {
      isRead: 1,
      lastActionTaken: lastActionTaken,
    });

    navigate(`/notifications/${id}`);
  };
  const onDismissClick = (id: string) => async () => {
    const lastActionTaken = new Date().valueOf();
    if (currentActiveIndex < notificationData.length - 1) {
      setCurrentIndex((index) => index++);
    } else {
      setIsMessageModalOpen(false);
    }
    if (isNotificationSyncDone) {
      api
        .saveSingleNotification({
          isNotificationRead: false,
          lastActionTakenSync: `${lastActionTaken}`,
          notificationId: id,
        })
        .catch((error) => {
          db.failedNotifications.add({
            isNotificationRead: 0,
            lastActionTakenSync: `${lastActionTaken}`,
            notificationId: id,
          });
          logging(
            'warn',
            'Unable to send single notification post request to backend',
            error,
            true,
            [
              {
                key: LogType.Home,
                value: `/dwar/api/acfs/Notification/saveNotification`,
              },
            ],
            false
          );
        });
    }

    db.notifications.update(id, {
      lastActionTaken: lastActionTaken,
    });
  };

  const title = notificationData[currentActiveIndex]?.text;
  const subtitle = notificationData[currentActiveIndex]?.description;

  const subtitleHtml = subtitle.replace(/<[^>]*>/g, ' ');

  return (
    <PopupModal isModalOpen={isMessageModalOpen}>
      <div
        className={
          'flex w-3/5 flex-col items-center rounded-lg bg-white px-5 py-4'
        }
      >
        <div className="mb-2 w-full ">
          <h1 className="text-xl font-bold">{title}</h1>

          <p className="line-clamp-3  text-lg">{subtitleHtml}</p>
        </div>
        <div className="flex gap-0.5">
          <Button onClick={onButtonClick(currentItemId)} styling="primary">
            <span>Ok</span>
          </Button>

          <Button onClick={onDismissClick(currentItemId)} styling="primary">
            <span>Dismiss</span>
          </Button>
        </div>
      </div>
    </PopupModal>
  );
};

export default NotificationPopups;
