import { useLiveQuery } from 'dexie-react-hooks';

import db from '../index-db';
import { NotificationType } from '../Models/Enumerations';
import { isToday } from '../utils';
import { useTranslation } from 'react-i18next';

const useUnreadAppNotifications = () => {
  const { i18n } = useTranslation();
  const langCode = i18n.language?.split('-')?.[0] ?? 'en';

  const unreadNotifications = useLiveQuery(() => {
    return db.notifications
      .where('notificationType')
      .equalsIgnoreCase(NotificationType.App)
      .and((notification) => {
        return Boolean(
          !notification?.isRead &&
            !isToday(Number(notification.lastActionTaken)) &&
            notification.languageCode === langCode
        );
      })
      .toArray();
  }, [langCode]);

  return unreadNotifications;
};

export default useUnreadAppNotifications;
