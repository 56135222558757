interface INotification {
  id: string;
  text: string;
  description: string;
  notificationType: string;
  creationtime: string;
  releasetime: string;
  languageCode: string;
  lastActionTaken: number;
  isRead?: number;
  isNotificationRead?: number;
  lastActionTakenSync?: string;
}

export type saveNotification = {
  isNotificationRead: boolean;
  notificationId: string;
  lastActionTakenSync: string;
};

export class Notification implements INotification {
  id: string;
  text: string;
  description: string;
  notificationType: string;
  creationtime: string;
  releasetime: string;
  languageCode: string;
  isRead?: number;
  lastActionTaken: number;
  userId?: string;
  isNotificationRead?: number;
  lastActionTakenSync?: string;

  constructor(
    id: string,
    text: string,
    description: string,
    notificationType: string,
    creationtime: string,
    releasetime: string,
    languageCode: string,
    lastActionTaken: number,
    isRead?: number,
    lastActionTakenSync?: string
  ) {
    this.id = id;
    this.text = text;
    this.description = description;
    this.notificationType = notificationType;
    this.creationtime = creationtime;
    this.releasetime = releasetime;
    this.languageCode = languageCode;
    this.isRead = isRead;
    this.lastActionTaken = lastActionTaken;
    this.lastActionTakenSync = lastActionTakenSync;
  }
}

export default Notification;
