import { useCallback, useEffect } from 'react';

import useLogging from './useLogging';
import populateNotificationsIndexdb from '../indexdbQueries/populateNotificationsIndexdb';
import { NOTIFICATION_SYNC_STATUS, notificationsFetchTime } from '../constants';

import Notification from '../Models/Notifications';
import { useApi } from '../acfs-apis/dwar-api-provider';

import { useTranslation } from 'react-i18next';
import db from '../index-db';
import { LogType } from '../enums/LogType';

const useLoadNotifications = () => {
  const logging = useLogging();
  const api = useApi();
  const { i18n } = useTranslation();
  const langCode = i18n.language?.split('-')?.[0] ?? 'en';

  const fetchNotificationsData = useCallback(async (): Promise<
    Notification[]
  > => {
    return (await api.fetchJson(
      '/dwar/api/acfs/Notification/getNotifications',
      {
        headers: { LanguageCode: langCode },
      }
    )) as Notification[];
  }, [api, langCode]);
  const isNotificationSyncDone =
    localStorage.getItem(NOTIFICATION_SYNC_STATUS) === 'true';

  const syncFailedNotifications = useCallback(async () => {
    const failedNotifications = (await db.failedNotifications.toArray()).map(
      (notification) => ({
        ...notification,
        isNotificationRead: Boolean(notification.isNotificationRead),
      })
    );
    if (failedNotifications.length) {
      try {
        await api.saveMultipleNotifications(failedNotifications);
        await db.failedNotifications.clear();
      } catch (e) {
        logging(
          'warn',
          'Unable to save failed notifications',
          e,
          true,
          [
            {
              key: LogType.App,
              value: `/dwar/api/acfs/Notification/saveNotifications`,
            },
          ],
          false
        );
      }
    }
  }, [api, logging]);

  const syncSaveNotificationsFirstTime = useCallback(async () => {
    if (!isNotificationSyncDone) {
      const localNotifications = await db.notifications.toArray();
      const saveNotificationsApiData = localNotifications
        .filter((notification) => Boolean(notification?.isRead))
        .map((notification) => ({
          isNotificationRead: Boolean(notification?.isRead),
          notificationId: notification.id,
          lastActionTakenSync: `${notification.lastActionTaken}`,
        }));
      try {
        if (saveNotificationsApiData.length) {
          await api.saveMultipleNotifications(saveNotificationsApiData);
        }
        localStorage.setItem(NOTIFICATION_SYNC_STATUS, 'true');
      } catch (error) {
        logging(
          'warn',
          'Unable to send mutliple notifications post request to backend',
          error,
          true,
          [
            {
              key: LogType.App,
              value: `/dwar/api/acfs/Notification/saveNotifications`,
            },
          ],
          false
        );
      }
    }
  }, [api, isNotificationSyncDone, logging]);

  useEffect(() => {
    const fetchNotifications = async () => {
      let notificationsData: Notification[] | undefined = undefined;
      try {
        await syncFailedNotifications();
        await syncSaveNotificationsFirstTime();
        notificationsData = await fetchNotificationsData();
      } catch (error) {
        setTimeout(async () => {
          try {
            notificationsData = await fetchNotificationsData();
          } catch (error) {
            logging(
              'warn',
              'Unable to fetch notifications from back-end',
              error,
              false,
              null
            );
          }
        }, 10000);
      }
      const lastActionTaken = new Date().valueOf() - 1000 * 60 * 60 * 24 * 2;
      try {
        if (notificationsData) {
          const hasSaveNotificationsRun = notificationsData.some((noti) =>
            Boolean(noti.lastActionTakenSync)
          );

          if (isNotificationSyncDone || hasSaveNotificationsRun) {
            await db.notifications.clear();
            await db.notifications.bulkPut(
              notificationsData.map((notif) => ({
                ...notif,
                lastActionTaken: Boolean(notif.lastActionTakenSync)
                  ? Number(notif.lastActionTakenSync)
                  : lastActionTaken,
                isRead: notif.isNotificationRead ? 1 : 0,
              }))
            );
          } else {
            await populateNotificationsIndexdb(notificationsData);
          }
          logging(
            'info',
            `Notifications added in the indexdb `,
            '',
            false,
            null
          );
        }
      } catch (error) {
        logging(
          'warn',
          'Unable to add notifications in the indexdb',
          error,
          false,
          null
        );
      }
    };
    fetchNotifications();
    const interval = setInterval(fetchNotifications, notificationsFetchTime);
    return () => clearInterval(interval);
  }, [
    fetchNotificationsData,
    isNotificationSyncDone,
    langCode,
    logging,
    syncFailedNotifications,
    syncSaveNotificationsFirstTime,
  ]);
};
export default useLoadNotifications;
