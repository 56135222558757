class FailedManuadlEndpoints {
  serialNumber: string;
  userTag?: string;
  isDeleted: boolean;
  externallyNavigated?: boolean;
  constructor(
    serialNumber: string,
    isDeleted: boolean,
    externallyNavigated?: boolean,
    userTag?: string
  ) {
    this.serialNumber = serialNumber;
    this.userTag = userTag;
    this.isDeleted = isDeleted;
    this.externallyNavigated = externallyNavigated;
  }
}

export default FailedManuadlEndpoints;
