import Insight from './Insight';
import TimelineEvent from './TimeLineEvent';
import ManualEndPoint from './ManualEndPoint';
import EndpointStatistics from './EndpointStatistics';
import Entitlement from './Entitlement';
import SoftwareInventory from './SoftwareInventory';

export interface IEndpointSummary {
  id: string;
  connected: boolean;
  customerName: string;
  serialNumber: string;
  terminalId: string;
  model: string;
  prodDesc: string;
  chronicIndicator: string;
}

export interface IEndPoint {
  id: string;
  connected: boolean;
  partySiteNum: string;
  customerId: string;
  customerName: string;
  address1: string;
  city: string;
  postalCode: string;
  state: string;
  country: string;
  branch: string;
  serialNumber: string;
  terminalId: string;
  region: string;
  model: string;
  dataSource: string;
  prodDesc: string;
  refreshId: string;
  serviceInsights: Insight[];
  preventiveInsights: Insight[];
  timelineEventDetails: TimelineEvent[];
  chronicDetails: string;
  generatedSummary: string;
  chronicIndicator: string;
  wpTrackTriageNotes: string;
  naTriageNotes: string;
  endpointStatistics: EndpointStatistics;
  installDate: string;
  duplicate: boolean;
  entitlements: Entitlement[];
  softwareInventory: SoftwareInventory[];
}

export class EndPoint {
  id: string;
  connected: boolean;
  partySiteNum: string;
  customerId: string;
  customerName: string;
  address1: string;
  city: string;
  postalCode: string;
  state: string;
  country: string;
  branch: string;
  serialNumber: string;
  terminalId: string;
  region: string;
  model: string;
  dataSource: string;
  prodDesc: string;
  refreshId: string;
  serviceInsights: Insight[];
  preventiveInsights: Insight[];
  timelineEventDetails: TimelineEvent[];
  chronicDetails: string;
  generatedSummary: string;
  chronicIndicator: string;
  wpTrackTriageNotes: string;
  naTriageNotes: string;
  endpointStatistics: EndpointStatistics;
  installDate: string;
  duplicate: boolean;
  entitlements: Entitlement[];
  softwareInventory: SoftwareInventory[];

  constructor(
    id: string,
    connected: boolean,
    partySiteNum: string,
    customerId: string,
    customerName: string,
    address1: string,
    city: string,
    postalCode: string,
    state: string,
    country: string,
    branch: string,
    serialNumber: string,
    terminalId: string,
    region: string,
    model: string,
    dataSource: string,
    prodDesc: string,
    refreshId: string,
    serviceInsights: Insight[],
    preventiveInsights: Insight[],
    timelineEventDetails: TimelineEvent[],
    chronicDetails: string,
    generatedSummary: string,
    chronicIndicator: string,
    wpTrackTriageNotes: string,
    naTriageNotes: string,
    endpointStatistics: EndpointStatistics,
    installDate: string,
    duplicate: boolean,
    entitlements: Entitlement[],
    softwareInventory: SoftwareInventory[]
  ) {
    this.id = id;
    this.connected = connected;
    this.partySiteNum = partySiteNum;
    this.customerId = customerId;
    this.customerName = customerName;
    this.address1 = address1;
    this.city = city;
    this.postalCode = postalCode;
    this.state = state;
    this.country = country;
    this.branch = branch;
    this.serialNumber = serialNumber;
    this.terminalId = terminalId;
    this.region = region;
    this.model = model;
    this.dataSource = dataSource;
    this.prodDesc = prodDesc;
    this.refreshId = refreshId;
    this.preventiveInsights = preventiveInsights;
    this.serviceInsights = serviceInsights;
    this.timelineEventDetails = timelineEventDetails;
    this.chronicDetails = chronicDetails;
    this.chronicIndicator = chronicIndicator;
    this.generatedSummary = generatedSummary;
    this.wpTrackTriageNotes = wpTrackTriageNotes;
    this.naTriageNotes = naTriageNotes;
    this.endpointStatistics = endpointStatistics;
    this.installDate = installDate;
    this.duplicate = duplicate;
    this.entitlements = entitlements;
    this.softwareInventory = softwareInventory;
  }

  public GetManualEndPoint(): ManualEndPoint {
    return new ManualEndPoint({
      id: this.id,
      serialNumber: this.serialNumber,
      prodDesc: this.prodDesc,
      terminalId: this.terminalId,
      customerName: this.customerName,
      preventiveInsights: this.preventiveInsights,
      serviceInsights: this.serviceInsights,
      chronicDetails: this.chronicDetails,
      generatedSummary: this.generatedSummary,
      chronicIndicator: this.chronicIndicator,
    });
  }
}

export default EndPoint;
