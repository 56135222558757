import { useTranslation } from 'react-i18next';
import ActionableListItem from '../../components/ActionableListItem';
import useGetAllNotifications from '../../hooks/useGetAllNotifications';

import { usePage } from '../../PageProvider';
import db from '../../index-db';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../acfs-apis/dwar-api-provider';
import { NOTIFICATION_SYNC_STATUS } from '../../constants';
import useLogging from '../../hooks/useLogging';
import { LogType } from '../../enums/LogType';

const NotificationsList = () => {
  const { notifications, isLoading } = useGetAllNotifications();

  const navigate = useNavigate();
  const { t } = useTranslation();
  const api = useApi();
  usePage(
    () => ({
      getTitle: () => t('Notifications'),
      belongsToNavBarItem: 'Notification',
      showBackButton: true,
    }),
    [t]
  );
  const logging = useLogging();
  if (isLoading) {
    return (
      <div className="py-4 text-center text-lg font-medium text-concrete">
        {t('Loading')}...
      </div>
    );
  }
  const isNotificationSyncDone =
    localStorage.getItem(NOTIFICATION_SYNC_STATUS) === 'true';

  const onItemClick = (id: string) => async () => {
    const lastActionTaken = new Date().valueOf();

    if (isNotificationSyncDone) {
      api
        .saveSingleNotification({
          isNotificationRead: true,
          lastActionTakenSync: `${lastActionTaken}`,
          notificationId: id,
        })
        .catch((error) => {
          db.failedNotifications.add({
            isNotificationRead: 1,
            lastActionTakenSync: `${lastActionTaken}`,
            notificationId: id,
          });
          logging(
            'warn',
            'Unable to send single notification post request to backend',
            error,
            true,
            [
              {
                key: LogType.NotificationList,
                value: `/dwar/api/acfs/Notification/saveNotification`,
              },
            ],
            false
          );
        });
    }
    db.notifications.update(id, {
      isRead: 1,
      lastActionTaken: lastActionTaken,
    });

    navigate(`/notifications/${id}`);
  };

  /**
   * 'setting'
  | 'nonBold'
  | 'cobaltTrailingIcon'
  | 'colorInverted';
   */

  return (
    <>
      {notifications.map((notification) => {
        const subtText = notification.description.replace(/<[^>]*>/g, ' ');

        return (
          <ActionableListItem
            key={notification.id}
            title={notification?.text}
            subText={subtText}
            primaryAction="navigate"
            onClick={onItemClick(notification?.id)}
            subTextPosition="below title"
            appearance={
              Boolean(notification.isRead) ? 'setting' : 'colorInverted'
            }
          />
        );
      })}
    </>
  );
};

export default NotificationsList;
