import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Button from '../../components/Button';
import MuiButton from '@mui/material/Button';

import SectionHeading from '../../components/SectionHeading';
import useTasks from '../../hooks/useTasks';
import { usePage } from '../../PageProvider';
import { useApi } from '../../acfs-apis/dwar-api-provider';
import useLogging from '../../hooks/useLogging';
import LogParams from '../../Models/LogParams';
import { LogType } from '../../enums/LogType';
import TaskListItem from './TaskListItem';
import EquipmentListItem from './EquipmentListItem';
import EndPoint from '../../Models/EndPoint';
import ManualEndPoint from '../../Models/ManualEndPoint';
import db from '../../index-db';
import { useAppSelector } from '../../app/hooks';
import useNavigateToEquipment from '../../hooks/useNavigateToEquipment';
import { EquipmentMenuItem } from '../../types';
import PopupModal from '../../components/PopupModal';
import { TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import AddEquipmentModal from '../../components/feature/AddEquipmentModal';
import { useToast } from '../../components/toast/ToastProvider';
import useIsOnline from '../../hooks/useIsOnline';
import syncManualEndpoints from './syncManualEndpoints';
import { deleteManualEndpoint } from './apis';

function MePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigateToEquipment = useNavigateToEquipment();
  const logging = useLogging();

  const api = useApi();
  const [showModal, setShowModal] = useState(false);

  const [isManualModalOpen, setIsManualModalOpen] = useState(false);
  const [currentEndpointId, setCurrentEndpointId] = useState('');
  const [currentSerialNumber, setCurrentSerialNumber] = useState('');
  const [manualEquipments, setManualEquipments] = useState(
    [] as ManualEndPoint[]
  );

  const latestFetchTimestamp = useAppSelector(
    (state) => state.tasks.latestFetchTimestamp
  );
  const [refreshTimeInterval, setRefreshTimeInterval] = useState(
    Math.floor(Number((Date.now() - latestFetchTimestamp) / 60000))
  );

  useEffect(() => {
    // Respond to outside changes
    setRefreshTimeInterval(
      Math.floor(Number((Date.now() - latestFetchTimestamp) / 60000))
    );
  }, [latestFetchTimestamp]);

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      modalTag: '',
    },
  });

  useEffect(() => {
    const logParams: LogParams[] = [
      {
        key: LogType.Page,
        value: LogType.Me,
      },
    ];
    logging('info', '', '', false, logParams);
  }, []);

  usePage(
    () => ({
      getTitle: () => t('Me'),
      belongsToNavBarItem: 'Me',
      showBackButton: true,
      rightButtons: [
        {
          icon: SettingsOutlinedIcon,
          onClick: () => {
            navigate('/settings');
          },
        },
      ],
    }),
    [navigate, t]
  );

  const [tasks, loadingTasks, forceReloadTasks] = useTasks();

  const previousTasksCount = useRef(0);
  useEffect(() => {
    if (!!tasks) {
      previousTasksCount.current = tasks.length;
    }
  }, [tasks]);

  const syncTasks = () => {
    if (!loadingTasks) {
      forceReloadTasks();
    }
  };
  const fetchEndPoints = useCallback(
    async (serialNumber: string): Promise<EndPoint[]> => {
      return (await api.fetchJson(
        `/dwar/api/almanac/ServiceEndPoint/getEndpointByFilter/?serialNumber=${serialNumber}`
      )) as EndPoint[];
    },
    [api]
  );
  const isOnline = useIsOnline();

  const toast = useToast();

  useEffect(() => {
    (async () => {
      try {
        const finalizedManualEndpoints = (
          await syncManualEndpoints(api, isOnline)
        ).filter((endpoint) => !endpoint?.externallyNavigated);

        setManualEquipments(finalizedManualEndpoints);
      } catch (error) {
        const manualEndpoint = (await db.manualEndPoints.toArray()).filter(
          (endpoint) => !endpoint?.externallyNavigated
        );
        setManualEquipments(manualEndpoint);
      }
    })();
  }, [fetchEndPoints, api, isOnline]);

  const onEquipmentItemClick = (id: string) => () => {
    navigateToEquipment(id, 'Systems' as EquipmentMenuItem);
  };

  const onEditClick =
    (id: string, serialNumber: string) => (e: React.SyntheticEvent) => {
      db.manualEndPoints.get(id).then((endpoint) => {
        methods.setValue('modalTag', endpoint?.userTag ?? '');
      });
      e.stopPropagation();

      setIsManualModalOpen(true);
      setCurrentEndpointId(id);
      setCurrentSerialNumber(serialNumber);
    };
  const findEquipmentFromState = useCallback(
    (serialNumber: string, prevEquipmentData: ManualEndPoint[]) => {
      const foundEquipment = prevEquipmentData.findIndex(
        (equipment) => equipment.serialNumber === serialNumber
      );
      return [prevEquipmentData.slice(), foundEquipment] as [
        ManualEndPoint[],
        number
      ];
    },
    []
  );

  const onOkClick = (data: { modalTag: string }) => {
    const { modalTag } = data;

    api
      .saveSingleUserEquipment({
        serialNumber: currentSerialNumber,
        userTag: modalTag,
        isExternallyNavigated: false,
      })
      .then(async () => {
        setManualEquipments((prevEquipmentData) => {
          const [newEquipmentArr, index] = findEquipmentFromState(
            currentSerialNumber,
            prevEquipmentData
          );
          index !== -1 &&
            newEquipmentArr.splice(index, 1, {
              ...newEquipmentArr[index],
              userTag: modalTag,
            });
          return newEquipmentArr;
        });
        db.manualEndPoints
          .update(currentEndpointId, {
            userTag: modalTag,
            externallyNavigated: false,
          })
          .catch((error) => {
            logging(
              LogType.Me,
              'Failure while saving the userTag for the manualequipment',
              error,
              true,
              [
                { key: 'serialNumber', value: currentSerialNumber },
                {
                  key: 'api',
                  value: '/dwar/api/almanac/ServiceEndPoint/saveUserEquipment',
                },
              ]
            );
          });
      })
      .catch((error) => {
        !isOnline &&
          db.failedManualEndpoints
            .put({
              serialNumber: currentSerialNumber,
              isDeleted: false,
              userTag: modalTag,
              externallyNavigated: false,
            })
            .catch((e) => {
              console.log(e);
            });
        logging(
          LogType.Me,
          'Failure in the saveSingleUserEquipment api ',
          error,
          true,
          [
            { key: 'serialNumber', value: currentSerialNumber },
            {
              key: 'api',
              value: '/dwar/api/almanac/ServiceEndPoint/saveUserEquipment',
            },
          ]
        );
        if (!isOnline) {
          toast.pushToast({
            type: 'info',
            message: t(
              'Currently offline, usertag will update when connected.'
            ),
          });
        } else {
          toast.pushToast({
            type: 'error',
            message: t(
              `Unable to update the usertag for the {{serialNumber}}`,
              {
                serialNumber: currentSerialNumber,
              }
            ),
          });
        }
      });
    methods.reset();
    setIsManualModalOpen(false);
  };
  const onCancelClick = () => {
    methods.reset();
    setIsManualModalOpen(false);
  };

  const handleCancel = () => {
    setShowModal(false);
  };
  const onModalClose = () => {
    setShowModal(false);
  };
  const handleAdd = (endpoint: ManualEndPoint) => {
    setManualEquipments((prevEquipmentData) => {
      const [, index] = findEquipmentFromState(
        endpoint.serialNumber,
        prevEquipmentData
      );
      return index === -1
        ? [...prevEquipmentData, endpoint]
        : prevEquipmentData;
    });
    setShowModal(false);
  };

  const onItemDelete = (serialNumber: string) => {
    deleteManualEndpoint(serialNumber, api)
      .then(() => {
        setManualEquipments((prevEquipmentData) => {
          const [newEquipmentArr, index] = findEquipmentFromState(
            serialNumber,
            prevEquipmentData
          );
          index !== -1 && newEquipmentArr.splice(index, 1);
          return newEquipmentArr;
        });
        db.manualEndPoints.where('serialNumber').equals(serialNumber).delete();
      })
      .catch((error) => {
        logging(
          LogType.Me,
          'Failure in the deleteSingleUserEquipment api',
          error,
          true,
          [
            { key: 'serialNumber', value: serialNumber },
            {
              key: 'api',
              value: '/dwar/api/almanac/ServiceEndPoint/deleteUserEquipment',
            },
          ]
        );

        if (!isOnline) {
          db.failedManualEndpoints
            .update(serialNumber, {
              isDeleted: true,
            })
            .then((isUpdated) => {
              if (!isUpdated) {
                db.failedManualEndpoints.add({ serialNumber, isDeleted: true });
              }
            });
          toast.pushToast({
            type: 'info',
            message: t(
              'Currently offline, endpoint will be removed when connected.'
            ),
          });
        } else {
          toast.pushToast({
            type: 'error',
            message: t(
              `Unable to delete the endpoint for the {{serialNumber}}`,
              {
                serialNumber,
              }
            ),
          });
        }
      });
  };

  return (
    <div>
      <div className="mt-4 text-center text-steel">
        {t('Last synced: {{time}} min ago', { time: refreshTimeInterval })}
      </div>

      <AddEquipmentModal
        onDismiss={handleCancel}
        onSubmit={handleAdd}
        isModalOpen={showModal}
        onModalClose={onModalClose}
      />

      <SectionHeading
        title={t('My tasks')}
        icon={EventOutlinedIcon}
        trailingElement={
          <Button
            styling="tertiary"
            icon={SyncOutlinedIcon}
            onClick={syncTasks}
          >
            {t('Sync')}
          </Button>
        }
      />

      {loadingTasks && (
        <>
          {Object.keys(Array(previousTasksCount.current || 1).fill('')).map(
            (index) => (
              <div
                key={index}
                className="h-14 border-b border-antartica bg-white"
              />
            )
          )}
        </>
      )}

      {!loadingTasks &&
        tasks?.map((task) => (
          <TaskListItem
            key={task.id}
            task={task}
            onClick={() => {
              navigateToEquipment(
                task.endpointId,
                'Systems' as EquipmentMenuItem,
                task.id
              );
            }}
          />
        ))}

      <SectionHeading
        title={t('Systems')}
        icon={StorageOutlinedIcon}
        trailingElement={
          <Button
            styling={'tertiary'}
            icon={AddOutlinedIcon}
            onClick={() => {
              setShowModal(true);
              const getMain = () => document.querySelector('main');
              getMain()?.scrollTo({ top: 0, behavior: 'smooth' });
            }}
          >
            {t('Add New')}
          </Button>
        }
      />

      {manualEquipments &&
        manualEquipments.map((endPoint: ManualEndPoint) => (
          <EquipmentListItem
            key={endPoint.id}
            manualEndPoint={endPoint}
            onClick={onEquipmentItemClick(endPoint.id)}
            onEditClick={onEditClick(endPoint.id, endPoint.serialNumber)}
            onItemDeleted={onItemDelete}
          />
        ))}
      <PopupModal isModalOpen={isManualModalOpen}>
        <form
          onSubmit={methods.handleSubmit(onOkClick)}
          className="w-11/12  rounded-lg bg-white px-2 py-4"
        >
          <div className="mb-4 flex items-center gap-1 px-4">
            <h2 className="text-lg font-bold">{t('Tag')}</h2>

            <TextField
              id="outlined-basic"
              variant="standard"
              multiline
              maxRows={1}
              fullWidth
              error={Boolean(methods.formState.errors?.modalTag)}
              helperText={methods.formState.errors?.modalTag?.message}
              {...methods.register('modalTag', {
                maxLength: {
                  value: 60,
                  message: 'Maximum number of characters allowed are 60.',
                },
              })}
            />
          </div>
          <div className="flex justify-center gap-1">
            <MuiButton
              variant="contained"
              type="submit"
              disabled={!methods.formState.isValid}
            >
              Update
            </MuiButton>
            <MuiButton variant="contained" onClick={onCancelClick}>
              Cancel
            </MuiButton>
          </div>
        </form>
      </PopupModal>
    </div>
  );
}

export default MePage;
