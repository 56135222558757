const translation = {
  'You switched to {{ language }}': 'przełączyłeś się na {{ language }}',
  'Polish': 'Polski',
  'Home': 'strona główna',
  'Search': 'szukaj',
  'Saved': 'zapisz',
  'Me': 'Ja',
  'Menu': 'Menu',
  'English': 'Angielski',
  'AllConnect Field Support': 'AllConnect Field Support',
  'Settings': 'ustawienia',
  'Language': 'język',
  'Saved Items': 'zapisane elementy',
  'My tasks': 'moje zadania',
  'Parts': 'części',
  'Saved items': 'zapisane elementy',
  'Notifications': 'powiadomienia',
  'Help': 'pomoc',
  'Feedback': 'Informacje zwrotne',
  'Feedback Status': 'Status informacji zwrotnej',
  'Repair Feedback': 'Informacje zwrotne dotyczące naprawy',
  'EFC Feedback': 'EFC Informacje zwrotne',
  'General Feedback': 'Ogólne informacje zwrotne',
  'Module Name': 'Nazwa modułu',
  'EFC Code': 'Kod EFC',
  'Your Feedback': 'Twoja opinia',
  'Field Feedback Requested': 'Prośba o opinie w terenie',
  'Level 4 to Review': 'Poziom 4 do przeglądu',
  'In Process by Level 4': 'W trakcie realizacji przez poziom 4',
  'Processed': 'Przetworzone',
  'Logout': 'wylogowanie',
  'Download for offline': 'pobranie offline',
  'Repair Action': 'naprawa',
  'Repair action': 'naprawa',
  'Part': 'cześć',
  'Refreshed {{time}} min ago': 'odświeżone{{time}}',
  'Recently viewed': 'ostatnio przeglądane',
  'View all': 'zobacz wszystko',
  'Clear All': 'wyszczyść wszystko',
  'Expand all': 'rozwiń ',
  'Collapse all': 'zwiń',
  'Search results': 'szukaj wyniku',
  'Repairs': 'naprawy',
  'Downloading content...': 'pobieranie zawartości..',
  'Bookmarks': 'zakładki',
  'Downloads': 'pobrania',
  'Recent': 'ostatnie',
  'Recent codes': 'ostatnie kody',
  'Saved codes': 'zapisane kody',
  'Updates': 'aktualizacje',
  'Clear': 'wyczyść',
  'Updated Content for {{item}}.': 'zaktualizuj zawartość {{item}}',
  '{{item}} has been saved': '{{item}} został zapisany',
  '{{item}} could not be saved': '{{item}} nie może być zapisany',
  '{{item}} has been removed from saved':
    '{{item}} został usunięty z zapisanych',
  '{{item}} could not be removed from saved':
    '{{item}} nie można usunąć z zapisanych',
  'Difficulty': 'problem',
  'Repair time': 'Termin naprawy',
  'min_one': '1 minuta',
  'min_other': '{{count}} minuta',
  'min_zero': '0 minuta',
  'Rating': 'ocena',
  'Thank you for the feedback': 'dziękujemy za podzielnie się opinią',
  'Not relevant': 'nie dotyczy',
  'Relevant': 'dotyczy',
  'Fixed my issue': 'rozwiązano problem',
  'Leave feedback': 'zostaw komentarz',
  'Save item': 'zapisz element',
  'Low': 'niski',
  'Medium': 'średni',
  'High': 'wysoki',
  'This was relevant info': 'to była istotna informacja',
  'This was not relevant info': 'to była nieistotna informacja',
  'Add to saved items': 'dodaj do zapisanych elementów',
  'Remove from downloads': 'usuń z pobranych',
  'Search AllConnect Field Support': 'szukaj AllConnect Field Support',
  'Filters': 'filtry',
  'Page not found': 'strone nieodnaleziona',
  'Return home': 'powrót do strony głównej',
  'Clear all bookmarks': 'wyczyść wszytkie zakładki',
  'Search all EFC codes': 'szukaj wszystkich kodów EFC ',
  'Search all parts': 'szukaj wszystkich części',
  'Results ({{resultsCount}})': 'wyniki ({{resultsCount}})',
  '{{item}} already saved': '{{item}} już zapisany',
  'Details': 'szczegóły',
  'Equipment': 'sprzęt',
  'Notes': 'notatki',
  'Equipment Information': 'informacje o sprzęcie',
  'Equipment ID': 'ID sprzętu',
  'Model': 'model',
  'Terminal ID': 'ID terminala',
  'Device serial number': 'numer seryjny urządzenia',
  'Add to equipment list': 'dodaj do listy sprzętu',
  'EFC codes': ' kod EFC',
  'Steps': 'kroki',
  'Module content': 'zawartość modułu',
  'Repair action content': 'szczegóły naprawy',
  'Fixed issue': 'problem rozwiązany',
  'Part Number {{partId}} copied to the clipboard': 'numer części {{partId}}',
  'Relationship tree': 'drzewako powiązań',
  'Potential substitutions': 'potencjalne zamienniki',
  'Loading': 'ładowanie',
  'Removed from saved searches': 'usuń z zapisanych poszukiwań',
  'Added to saved searches': 'dodaj do zapisanych poszukiwań',
  'All bookmarks cleared': 'wszystkie zakładki wyczyszczone',
  'No bookmarks to clear': 'nie ma zakładek do wyczyszczenia',
  'All recent items cleared': 'wszytskie ostatnie obiekty wyczyszczone',
  'No recent items to clear': 'nie ma nic do wyczyszczenia',
  'You have no recently view history.': 'nie masz nic w historii przegladania',
  'Please wait while data is being loaded in {{language}}':
    'Proszę czekać dane w języku {{language}} są obecnie ładowane',
  'Signing you in': ' Logowanie',
  'Signing you out': 'wylogowywanie',
  'Oops': 'oops',
  'Data has been fetched in': 'dane zostały pobrane',
  'Failed to fetch data in': 'błąd pobrania danych',
  'No results': 'brak wyników',
  'Preventive Insights': 'uwagi do konserwacji',
  'Service Insights': 'uwagi do naprawy serwisowej',
  'Switching language is done in 2 phases. The static data such as labels and headings will be switched immediately. The content data requires an online connection and can take up to a minute or more depending on your connection to refresh the new language choice.  Until content is downloaded the app will continue to show the previous language for content.':
    'Przełączanie języka odbywa się w 2 fazach. Dane statyczne, takie jak etykiety i nagłówki, zostaną natychmiast przełączone. Niektóre dane wymagają połączenia z Internetem i odświeżenia do nowego języka może potrwać do minuty lub dłużej, w zależności od połączenia.  Do momentu pobrania zawartości aplikacja będzie nadal wyświetlać zawartosci w poprzednim języku ',
  'URL': 'URL',
  'User Name': 'nazwa użytkownika',
  'ACFS App': 'Aplikacja ACFS',
  'ACFS UI': 'ACFS UI',
  'Offline Data': 'dane offline',
  'Info': 'informacja',
  'Repair': 'naprawa',
  'RepairActionCount 1': '1 naprawa',
  'RepairActionCount {{count}}': ' {{count}} naprawy ',
  'RepairActionCount 0': '0 napraw',
  'All': 'wszystko',
  'Sync': 'synchronizacja',
  'Last 30 days': 'ostatnie 30 dni',
  'Not yet implemented': 'jeszcze niezaimplementowane',
  'Repair actions': 'naprawa',
  'Module': 'moduł',
  'Preferences': 'preferencje',
  'Service Manuals': 'Instrukcja serwisowa',
  'Service Bulletins': 'Biuletyn serwisowy',
  'FCOs': 'FCOs',
  'FAQs': 'FAQs',
  'Training Content': 'zawartość szkolenia',
  'Your feedback has been sent': 'Twoja opinia został wysłany',
  'Failed to send your feedback': 'błąd w wysłaniu Twojej opini',
  'Comments are required': 'wymagany komentarz',
  'Comments should not be more than 100 characters':
    'komentarz nie może być dłuższy niż 100 znaków',
  'Max {{numOfPhotos}} photos can be uploaded':
    'Max {{numOfPhotos}} zdjęć może zostać załadowane',
  'Add this Task - {{taskNumber}}': 'dodaje zadanie {{taskNumber}}',
  "Other '{{inputValue}}'": 'inne {{inputValue}}',
  'Other (Type to add) / Blank': 'Inne ( typ do dodania)/puste',
  'General feedback': ' ogólny feedback',
  'Feedback: EFC code - {{efcCode}}': 'Opinia: EFC Code {{efcCode}}',
  'Module: {{moduleName}}': 'Moduł {{moduleName}}',
  'Feedback for a specific EFC Code, Repair Action, or Part should be submitted using the feedback option available on those specific entries.':
    'Opinię dotyczącą określonego kodu EFC, akcji naprawczej lub części należy przesłać przy użyciu opcji opinii dostępnej dla tych konkretnych wpisów',
  'Corrective action required': 'wymagana akcja naprawcza ',
  'Yes': 'tak',
  'No': 'nie',
  'Task number': 'numer zadania',
  'Select or Type to add': 'Wybierz lub qpisz do dodania',
  'Comments': 'komentarze',
  'Delete': 'usuń',
  'Open Camera': 'kamera włączona',
  'Upto {{maxFiles}} images can be selected':
    'do {{maxFiles}}zdjęć może być wybrane',
  'Cancel': 'anuluj',
  'Add': 'dodaj',
  'Add equipment': 'dodaj sprzęt',
  'Serial number': 'numer seryjny',
  'Scan the equipment code or enter the serial number to view the insights and details for that piece of equipment.':
    'Zeskanuj kod urządzenia lub wprowadź numer seryjny, aby wyświetlić szczegółowe informacje i szczegóły dotyczące tego urządzenia',
  'Last synced: {{time}} min ago': 'ostatnia synchronizacja {{time}} min temu',
  'Send': 'wyślij',
  "Task Number must be provided if 'Corrective action required' is Yes":
    'Należy podać numer zadania, jeśli opcja "Wymagane działanie naprawcze" ma wartość Tak',
  'Time to fix (minutes)': 'czas na naprawę ',
  'Time to fix should not be more than 999':
    'Czas naprawy nie powinien być dłuższy niż 999',
  'Please enter positive numbers only': 'Podaj tylko liczby dodatnie',
  'Please enter numbers only': 'Podaj tylko liczby',
  'Clear filters': 'Wyczyść filtry',
  'Show results': 'Pokaż wyniki',
  'Your feedback has been submitted': 'Twoja opinia została przesłana',
  'This EFC is related to an enrichment link that is currently active.Please let us know if the enrichment is relevant (appropriate action to perform).If you want to leave additional feedback select "Leave feedback"':
    'The cos EFC związany jest z linkiem do enrichmentu, który jest aktulanie aktywnyProszę daj znać czy enrichment był pomocny(odpowienia akcja do wykonania została wskazana)jeśli chcesz zostawićdodatkowy komentarz kliknij "zostaw opinie" ',
  'Products': 'produkty',
  'Product Number {{productId}} copied to the clipboard':
    'Numer produktu {{productId}} skopiowany do schowka',
  'View Groups': 'Wyświetlanie grup',
  'Search all products': 'Wyszukaj wszystkie produkty',
  'Current': 'Bieżące',
  'History': 'Historia',
  'No equipment data found for serial number {{number}}.':
    'Nie znaleziono danych dotyczących numeru seryjnego sprzętu {{number}}',
  'Unable to retrieve equipment data for the serial number {{number}}.':
    'Nie można pobrać danych dotyczących numeru seryjnego sprzętu {{number}}',
  'Unable to retrieve equipment data. Falling back to offline data.':
    'Nie można pobrać danych sprzętu. Powrót do danych offline',
  'Unable to retrieve service task. Falling back to offline data.':
    'Nie można pobrać zadania serwisowego. Powrót do danych offline',
  'Errors': 'Błędy',
  'Tasks': 'Zadania',
  'History Parts': 'historia cześći',
  'Unable to retrieve Service Task history.':
    'nie można pobrać historii zadań serwisowych',
  'Recommended Parts': 'Zalecane części',
  'Service Task Details': 'Szczegóły zadania serwisowego',
  'Task Number': 'Numer zadania',
  'Task Status': 'status zadania',
  'Problem': 'problem',
  'Task Type': 'typ zadania',
  'Customer': 'Klient',
  'Effective Date': 'Data wejścia w życie',
  'Device Description': 'Opis urządzenia',
  'Device Serial Number': 'Numer seryjny urządzenia',
  'EndPoint Id': 'Identyfikator punktu końcowego',
  'Service Task Notes {{length}}.':
    'Uwagi dotyczące zadań serwisowych {{length}}.',
  'Part {{partId}} cannot be found in DNSpares data':
    'Cześć {{partId}} nieznalznionaw DNspare',
  'Number': 'Numer',
  'Site Number': 'numer strony',
  'Address': 'adres',
  'Contact Date': 'Data kontaktu',
  'Contact Name': 'Imię i nazwisko osoby kontaktowej',
  'Contact Email': 'Kontaktowy adres e-mail',
  'Contact Phone': 'Kontaktowy numer telefonu',
  'Contract Hours': 'godziny kontaktu',
  'Site Messages:': 'Komunikaty witryny:',
  'Type': 'typ',
  'Product': 'produkt',
  'Desc': 'opis',
  'Task Number should not be more than 30 characters':
    'Numer zadania nie powinien być dłuższy niż 30 znaków',
  'Select Photos': 'Wybierz zdjęcia',
  'Session Expired': 'Sesja wygasła',
  'The current session has expired while offline. Please bring the device back online and select the Login button below to perform the login and take you back to the home screen.':
    "Bieżąca sesja wygasła w trybie offline.Przywróć urządzenie do trybu online i wybierz poniżej przycisk 'Zaloguj się', aby przeprowadzić logowanie i wrócić do ekranu głównego",
  'Login': 'Login',
  'Please provide your feedback in order to improve the content in the ACFS Site not related to a specific EFC or Repair Action.':
    'Prosimy o przekazanie opinii w celu upoprawy treści ACFS niezwiązanych z konkretnym EFC lub naprawą',
  'Please provide your feedback in order to improve the content that follows this EFC Code.':
    'Prosimy o przekazanie opinii w celu ulepszenia treści zgodnych z niniejszym koderem EFC',
  'Is this Repair Action missing anything? For example, should the star rating or time to fix be adjusted? Please provide your feedback in order to improve the content of this specific Repair Action and its association with the EFC that prompted this.':
    'Czy  w tej akcji naprawczej można coś ulepszyć? Na przykład, czy należy dostosować ocenę gwiazdkową lub czas naprawy? Proszę przekazać swoją opinię, aby ulepszyć treść tej konkretnej akcji naprawczej i jej powiązania z kodem EFC.',
  'Select "Yes" if you are recommending that a change needs to be made by the administrators.':
    'wybierz "Tak", jeśli zalecasz zmianę przez administratorów',
  'Feedback is not mandatory.Please use English if possible. Feedback is used to improve the content in ACFS. Feedback can be shared with management to ensure quality. Administrators may contact individuals for additional information to help clarify any information needed to improve content or other business purposes.':
    'Opnia nie jest obowiązkowa.Jeśli to możliwe, używaj języka angielskiego. Opinia używana jest w celu poprawy zawartości ACFS. Opinia może być udostępniona kierownictwu w celu zapewnienia jakości. Administratorzy mogą kontaktować się z osobami fizycznymi w celu uzyskania dodatkowych informacji w celu wyjaśnienia wszelkich informacji potrzebnych do ulepszenia treści lub innych celów biznesowych',
  'OfflineMessage':
    'Gdy ACFS jest w trybie offline, niektóre treści nie będą dostępne ze względu na charakter treści, częstotliwość aktualizacji i ograniczenia przechowywania w urządzeniu końcowym.Ze względu na te....',
  'OfflineMessage1':
    'Na stronach Strona główna i Ja w sekcji Moje zadania będą wyświetlane tylko otwarte zadania dostępne na urządzeniu',
  'OfflineMessage2':
    'Na stronach związanych ze sprzętem (Wyposażenie, Szczegóły, Części) dane dotyczące sprzętu i zadań serwisowych będą wyświetlane na podstawie danych dostępnych w urządzeniu',
  'OfflineMessage3':
    'Na stronie Notatki będą wyświetlane tylko notatki dotyczące otwartych zadań serwisowych użytkownika, które są dostępne w urządzeniu korespondującym z urządzeniem',
  'OfflineMessage4':
    'Funkcje Dodaj wyposażenie na stronie Ja i strony Wyposażenie nie będą działać, ponieważ nie można pobrać sprzętu na podstawie numeru seryjnego podanego przez użytkownika',
  'Feedback: Repair # {{repairActionId}}': 'Opinia: Naprawa #',
  'Training': 'Szkolenie',
  'General Help': 'pomoc ogólna',
  'Offline Disclaimer': 'Zastrzeżenie offline',
  'Nothing here yet': 'Nic tu jeszcze nie ma',
  'Unable to retrieve tasks. Falling back to offline data.':
    'Nie można pobrać zadań. Powrót do danych offline',
  'Category': 'Kategoria',
  'Family': 'Rodzina',
  'Skill level required': 'Wymagany poziom umiejętności',
  'Sort by': 'Sortuj według',
  'Subsystem': 'Podsystem',
  'Recent records': 'Najnowsze rekordy',
  'Systems': 'systemy',
  'Task - {{taskNumber}}': 'zadanie -{{taskNumber}}',
  'Add New': 'dodaj nowe',
  'Everywhere': 'wszędzie',
  'Keywords': 'słowa kluczowe',
  'The details page contains content only when there is an active call assigned to the current user for this equipment.':
    'Strona Szczegóły zawiera zawartość tylko wtedy, gdy do bieżącego użytkownika przypisano aktywne wywołanie dla tego urządzenia',
  'Categories': 'Kategorie',
  'OfflineMessage5':
    'Zdjęcia w  aplikacji, takie jak zdjęcia do kodów EFC,kroków czynności naprawczych, części nie będą wyświetlane, jeśli użytkownik jest offline',
  'Module(EFC only)': 'Moduł (tylko EFC )',
  'German': ' Niemicki',
  'Francias': 'Francuski',
  'Espanol': 'Hiszpański',
  'Indonesian': 'Indonezyjski',
  'Chinese': 'Chiński',
  'Thai': 'Tajski',
  'Vietnamese': 'Vietnamski',
  'Portuguese': 'Portugalski',
  'Turkish': 'Turecki',
  'If no stock, replaces': 'Jeśli nie ma zapasów, wymień',
  'If no stock, replaced by': 'Jeśli nie ma zapasów, zastąpiony przez',
  'Replaces': 'Zastępuje',
  'Replaced by': 'Zastępuje',
  'Repair Id': 'Identyfikator naprawy',
  'View Child Ids': 'Wyświetlanie identyfikatorów podrzędnych',
  'Device Id': 'Identyfikator urządzenia',
  'Equipment with serial number {{number}} added Succesfully.':
    'Dodano sprzęt o numerze seryjnym {{numer}}.',
  'mfg': 'numer fabryczny',
  'Reporting System': 'System raportowania',
  'Update': 'aktualizacja',
  'No Endpoint': '„Brak wskazania wartości”',
  'Repair Action performed': 'Czy podjęto wskazane czynności naprawcze',
  'Update: User names are now visible in task history. You’ll see names of users, and others will see yours. This change helps identify previous users, making it easier to contact them for further information if needed.':
    'Aktualizacja: Nazwy użytkowników są teraz widoczne w historii zadań. Zobaczysz nazwy innych użytkowników, a inni zobaczą twoją. Ta zmiana pomaga zidentyfikować wcześniejszych użytkowników, ułatwiając kontakt z nimi w celu uzyskania dalszych informacji, jeśli będzie to konieczne.',
  'The ACDE Knowledgebase content is currently being downloaded to this device.  We apologize for the inconvenience.  The page will automatically redirect when the content load is complete.':
    'Zawartość bazy wiedzy ACDE jest aktualnie pobierana na urządzenie. Przepraszamy za utrudnienia. Nastąpi automatyczne przekierowanie na stronę po zakończeniu ładowania.',
  'We apologize for the inconvenience.  The link used is not a valid location in ACFS.':
    'Przepraszamy za utrudnienia. Zawartość, do której prowadzi przekierowanie nie jest poprawna w ACFS.',
  'The selected endpoint on the Repair Action Page does not match one of the endpoint of the active service task assigned. Select OK to continue. Cancel to change.':
    'Wybrany opis na stronie działań naprawczych nie zgadza się z przydzielonym zadaniem napawczym. Wybierz "OK" by kontynuować lub "Porzuć" aby zmienić.',
  'Select “Yes” if you are recommending that a new Repair Action needs to be created by the administrators.':
    'Wybierz "Tak" jeśli rekomendujesz dodanie nowej akcji naprawczej przez administratorów.',
  'Select this repair action template to specify the action completed':
    'Wybierz ten wzór akcji naprawczej aby opisać, że akcja została zakończona',
  'Custom Repair Performed': 'Naprawa Wykonana przez Klienta',
  'Submit': 'Potwierdzam',
  'Needs reviewed for new RA':
    'Konieczne sprawdzenie dla utworzenia nowej akcji naprawczej',
  'Title': 'Nazwa',
  'Title of repair': 'Nazwa naprawy',
  'Description of repair': 'Opis naprawy',
  'Duration to complete repair (minutes)': 'Czas całkowitej naprawy (minuty)',
  '3 or more Characters are required for the search':
    'Aby wyszukiwać podaj 3 lub więcej znaków',
  'Switching language is done in 2 phases. The static data such as labels and headings will be switched immediately. The content data requires an online connection and can take up to a minute or more depending on your connection to refresh the new language choice. Until content is downloaded the app will continue to show the previous language for content.  Recently viewed and Saved items will be the last to update and can take up to an additional minute.':
    'Zmiana języka odbywa się w 2 fazach. Dane statyczne, takie jak etykiety i nagłówki, zostaną natychmiast zamienione. Dane dotyczące zawartości wymagają połączenia internetowego, a odświeżenie nowego wyboru języka może zająć do minuty lub dłużej, w zależności od szybkości połączenia. Do czasu pobrania treści w nowym języku aplikacja będzie nadal wyświetlać treści w poprzednim języku. Ostatnio przeglądane i zapisane elementy zosstaną aktualizowane jako ostatnie i może to zająć dodatkowy czas.',
  'No results Or Multiple Results, please narrow your search':
    'Brak rezultatu lub wiele możliwych odpowiedzi. Zawęź kryteria wyszukiwania.',
  'Enter the serial number to view the insights and details for that piece of equipment.':
    'Wprowadź numer seryjny aby zobaczyć komponenty oraz szczegóły tego sprzetu.',
  'Open': 'Otwórz',
  'Interrupt': 'Przerwij',
  'Incomplete': 'Niekompletne',
  'Closed': 'Zamknij',
  'Cancelled': 'Porzuć',
  'Scanned successfully, Redirecting to the scanned link':
    'Skanowanie zakończone. Przekierowanie do linku ze skanem.',
  'Search Serial number': 'Wyszukiwanie numeru seryjnego',
  'No System is Selected': 'Nie wybrano żadnego systemu',
  'Cancelled Tasks': 'Anulowane zadania',
  'Allowed': 'Dozwolone',
  'Not Allowed': 'Niedozwolone',
  'Open Task UserId': 'Identyfikator użytkownika zadania otwartego',
  'Observations': 'Obserwacje',
  'GroupName or UserID': 'NazwaGrupy lub ID Użytkownika',
  'AI Summary': 'Podsumowanie sztucznej inteligencji',
  'Chronic / Worst Performer Details':
    'Szczegóły dotyczące przewlekłych / najgorszych wyników',
  'Triage Notes': "Uwagi dotyczące triage'u",
  'Search By': 'Szukaj według',
  'Serial Number': 'Numer seryjny',
  'Tag': 'Etykietka',
  'Temporarily add equipment': 'Tymczasowe dodanie sprzętu',
  'System': 'Układ',
  'Machine Name': 'Nazwa Maszyny',
  'No data is available': 'Brak dostępnych danych',
  'Connections': 'Połączenia',
  'Non-Connected': 'Niepołączony',
  'Part Recommendations': 'Zalecenia dotyczące części',
  'Please wait for the daily update.  The page will automatically refresh when completed.':
    'Poczekaj na codzienną aktualizację.  Strona zostanie automatycznie odświeżona po jej zakończeniu.',
  'Install Date': 'Data instalacji',
  'ACDE KB daily update is currently in progress. Part navigation is not available. RepairAction indicator is also not available. Please try again when the content has been downloaded.':
    'Trwa codzienna aktualizacja ACDE KB. Nawigacja po częściach jest niedostępna. Wskaźnik RepairAction jest również niedostępny. Spróbuj ponownie, gdy zawartość zostanie pobrana.',
  'No Equipment data available for the Endpoint {{endPointId}}.':
    'Brak dostępnych danych sprzętu dla punktu końcowego {{endPointId}}.',
  'Duplicate endpoint has been identified. Historical EFCs may have come from multiple endpoints. Caution should be taken when reviewing the history of this endpoints. Validate that Harvester is setup properly with the correct endpoint registration.':
    'Zidentyfikowano zduplikowany punkt końcowy. Historyczne EFC mogły pochodzić z wielu punktów końcowych. Należy zachować ostrożność podczas przeglądania historii tego punktu końcowego. Sprawdź, czy Harvester jest prawidłowo skonfigurowany z prawidłową rejestracją punktu końcowego.',
  'Duplicate Endpoint': 'Zduplikowany punkt końcowy',
  'Inventory': 'Inwentaryzacja',
  'Entitlements': 'Uprawnienia',
  'Green': 'Zielony',
  'Yellow': 'Żółty',
  'Red': 'Czerwony',
  'Empty': 'Pusty',
  'Entitlement is connected and sending data as expected.':
    'Uprawnienie jest podłączone i wysyła dane zgodnie z oczekiwaniami.',
  'Entitlement is connected, but not sending data as expected.  Review the detailed message on the entitlement details page. Click on card to get details on how to resolve.':
    'Uprawnienie jest połączone, ale nie wysyła danych zgodnie z oczekiwaniami.  Przejrzyj szczegółowy komunikat na stronie szczegółów uprawnienia. Kliknij kartę, aby uzyskać szczegółowe informacje na temat rozwiązania.',
  'Entitlement is expected, but is not connected and no data.  Click on card to get details on how to resolve.':
    'Uprawnienie jest oczekiwane, ale nie jest połączone i nie ma danych.  Kliknij kartę, aby uzyskać szczegółowe informacje na temat rozwiązania.',
  'Entitlement does not send information as to the current status. No action is required.':
    'Uprawnienie nie wysyła informacji o aktualnym statusie. Nie jest wymagane żadne działanie.',
  'Currently offline, endpoint will be removed when connected.':
    'Obecnie offline, punkt końcowy zostanie usunięty po połączeniu',
  'Currently offline, usertag will update when connected.':
    'Obecnie offline, usertag zostanie zaktualizowany po nawiązaniu połączenia.',
  'A disconnected system was previously connected and sending data to ACDE. However, data transmission has now stopped, exceeding the expected timeframe for this connection. Please refer to the ‘Last Data’ field to determine when data was last received.':
    'Odłączony system był wcześniej podłączony i wysyłał dane do ACDE. Jednak transmisja danych została zatrzymana, przekraczając oczekiwane ramy czasowe dla tego połączenia. Sprawdź pole „Ostatnie dane”, aby określić, kiedy dane zostały ostatnio odebrane.',
  'Disconnected': 'Odłączony',
  'Software Updated': 'Aktualizacja oprogramowania',
  'Software': 'Oprogramowanie',
  'components updated': 'zaktualizowane komponenty',
  'component updated': 'zaktualizowany komponent',
  'Last Data': 'Ostatnie dane',
  'First Data': 'Pierwsze dane',
  'Data Origin': 'Pochodzenie danych',
  'Avg Data Size': 'Średni rozmiar danych',
  'Transport': 'Transport',
  'Version': 'Wersja',
  'Last Update': 'Ostatnia aktualizacja',
  'Filter by Software': 'Filtruj według oprogramowania',
  'Filter by Version': 'Filtruj według wersji',
  'Filter by Last Update': 'Filtruj według ostatniej aktualizacji',
  'Please enter some text': 'Wprowadź tekst',
  'Serial Number already exists.': 'Numer seryjny już istnieje.',
  'Managed Services': 'Usługi zarządzane',
  'True': 'Prawda',
};

export default translation;
