import { useTranslation } from 'react-i18next';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { PropsWithChildren } from 'react';
import Sheet from 'react-modal-sheet';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { LogType } from '../../enums/LogType';
import { FbType } from '../../enums/FbType';
import {
  useForm,
  SubmitHandler,
  SubmitErrorHandler,
  Controller,
  FormProvider,
} from 'react-hook-form';

import { FormControlLabel, TextField } from '@mui/material';
import { useLiveQuery } from 'dexie-react-hooks';
import db from '../../index-db';
import { MultipleFileSelect } from './MultipleFileSelect';
import Feedback from '../../Models/Feedback';
import { useApi } from '../../acfs-apis/dwar-api-provider';
import { useToast } from '../../components/toast/ToastProvider';
import StarRatingForm from '../../components/StarRatingForm';
import useLogging from '../../hooks/useLogging';
import LogParams from '../../Models/LogParams';
import useCheckDeviceType from '../../hooks/useCheckDeviceType';
import Task from '../../Models/Task';

import useGetEndpointDetails from '../../hooks/useGetEndpointDetails';

type FeedbackType = 'general' | 'efc' | 'repairAction' | 'part';

interface RequiredProps {
  feedbackType: FeedbackType;
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (raPerformed: boolean) => void;
}

interface OptionalProps {
  className?: string;
  efcId?: number;
  repairActionId?: number;
  moduleId?: number;
  efcDesciption?: string;
  repairActionDescription?: string;
  efcCode?: string;
  repairActionName?: string;
  partNumber?: string;
  partName?: string;
}

type Props = RequiredProps & OptionalProps;

export interface IFormInput {
  correctiveActionRequired: boolean;
  repairActionPerformed: boolean;
  comments: string;
  photos: File[];
  rating: number;
  timeToFix: number;
}
const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  'width': 42,
  'height': 26,
  'padding': 0,
  '& .MuiSwitch-switchBase': {
    'padding': 0,
    'margin': 2,
    'transitionDuration': '300ms',
    '&.Mui-checked': {
      'transform': 'translateX(16px)',
      'color': '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#0769B7',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#7d878e33' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
function FeedbackScreen(props: PropsWithChildren<Props>) {
  const { t } = useTranslation();
  const { pushToast } = useToast();
  const logging = useLogging();
  const api = useApi();

  const deviceType = useCheckDeviceType();
  const methods = useForm<IFormInput>({
    mode: 'all',
    defaultValues: {
      correctiveActionRequired: false,
      repairActionPerformed: true,
      comments: '',
      photos: [],
      rating: 0,
    },
  });
  function blobToArrayBuffer(blob: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener('loadend', () => {
        resolve(reader.result);
      });
      reader.addEventListener('error', reject);
      reader.readAsArrayBuffer(blob);
    });
  }

  const [lastAccessedEndpoint, currentSerialNumber] = useGetEndpointDetails();
  const fetchOpenServiceTasks = async (): Promise<Task[]> => {
    return (await api.fetchJson(
      '/dwar/api/almanac/ServiceTask/getOpenServiceTasks'
    )) as Task[];
  };

  const getDefaultServiceTask = async () => {
    const tasks = await fetchOpenServiceTasks();
    const filteredTasksOnsite = tasks.find(
      (task) =>
        task.sharedStatus === 'On-site' &&
        task.endpointId === lastAccessedEndpoint
    );
    const filteredTasksTravel = tasks.find(
      (task) =>
        task.sharedStatus === 'Travel' &&
        task.endpointId === lastAccessedEndpoint
    );

    const filteredTasksCommitted = tasks.find(
      (task) =>
        task.sharedStatus === 'Committed' &&
        task.endpointId === lastAccessedEndpoint
    );

    return (
      filteredTasksOnsite?.taskNumber ||
      filteredTasksTravel?.taskNumber ||
      filteredTasksCommitted?.taskNumber ||
      (lastAccessedEndpoint !== '' ? currentSerialNumber : '')
    );
  };

  async function postFeedback(
    feedback: Feedback,
    raPerformed: boolean,
    isiOS: boolean
  ) {
    let logs = feedback;

    if (feedback.taskActivityNumber === currentSerialNumber) {
      logs = { ...feedback, taskActivityNumber: '' };
    }
    if (props.feedbackType === FbType.Part) {
      let logParams = logFeedBack(props, logs);
      logParams.push({
        key: LogType.FeedBackComments,
        value: feedback?.comments,
      });
      logging('info', '', '', false, logParams, true);

      methods.reset();
      props.handleSubmit(raPerformed);

      pushToast({
        message: t(`Your feedback has been sent`),
        type: 'success',
      });
    } else {
      try {
        const res = await api.postFormData(
          '/dwar/api/feedback/FeedBack/saveFeedback',
          feedback,
          isiOS
        );

        if (res === true) {
          pushToast({
            message: t(`Your feedback has been sent`),
            type: 'success',
          });
        } else {
          pushToast({
            message: t(`Failed to send your feedback`),
            type: 'error',
          });
        }
      } catch (error) {
        pushToast({
          message: t(
            'Failed to sent feedback, will retry when internet is restored'
          ),
          type: 'error',
        });
        logging('error', '', error, true, null, false);
      }
      props.handleSubmit(raPerformed);
      let logParams = logFeedBack(props, logs);
      logParams.push({
        key: LogType.FeedBackComments,
        value: feedback?.comments,
      });

      if (props.feedbackType == FbType.RepairAction) {
        logParams.push({
          key: LogType.RepairActionPerformed,
          value: raPerformed.toString(),
        });
      }

      logging('info', '', '', false, logParams, true);
      methods.reset();
    }
  }

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    const raPerformed = data.repairActionPerformed ?? true;
    const taskNumber = await getDefaultServiceTask();
    let isiOS = false;
    const photosBuffer: any[] = [];
    if (deviceType === 'iOS') {
      isiOS = true;
      await Promise.resolve(
        Promise.all(
          data.photos.map(async (f: File) => {
            const arrayBuffer = await blobToArrayBuffer(f);
            photosBuffer.push(arrayBuffer);
          })
        )
      );
    }

    const feedback: Feedback = {
      raId: props.repairActionId ?? 0,
      odysseyModuleId: props.moduleId ?? 0,
      efcCodeId: props.efcId ?? 0,
      recommendRating: data.rating ?? 0,
      timeToFix: data.timeToFix ?? 0,
      comments: data.comments ?? '',
      ratingAccepted: 0,
      timeAccepted: 0,
      taskActivityNumber: taskNumber ?? '',
      correctiveActionRequired: data.correctiveActionRequired ?? false,
      imageData: data.photos,
      imageBuffer: photosBuffer,
      partNumber: props.partNumber ?? '',
      partName: props.partName ?? '',
      endpointId: lastAccessedEndpoint ?? '',
      serialNumber: currentSerialNumber ?? '',
    };

    postFeedback(feedback, raPerformed, isiOS);
  };

  const handleError: SubmitErrorHandler<IFormInput> = (errors) => {
    logging('error', `error while submitting feedback`, errors, false, null);
  };

  const moduleName = useLiveQuery(async () => {
    if (props.moduleId) {
      const moduleRecord = await db.modules.get(props.moduleId);
      return moduleRecord?.ModuleName;
    }
  }, [props.moduleId]);

  const renderTitle = () => {
    return (
      <>
        <div className="mt-2 text-xl font-medium leading-6 ">
          {
            {
              general: t('General feedback'),
              efc: t('Feedback: EFC code - {{efcCode}}', {
                efcCode: props.efcCode,
              }),
              repairAction: t('Feedback: Repair # {{repairActionId}}', {
                repairActionId: props.repairActionId,
              }),
              part: t('Feedback: Part # {{partNumber}}', {
                partNumber: props.partNumber,
              }),
            }[props.feedbackType]
          }
        </div>

        <p className="mt-1 text-base leading-5 text-steel">
          {
            {
              general: t(
                'Please provide your feedback in order to improve the content in the ACFS Site not related to a specific EFC or Repair Action.'
              ),
              efc: t(
                'Please provide your feedback in order to improve the content that follows this EFC Code.'
              ),
              repairAction: t(
                'Is this Repair Action missing anything? For example, should the star rating or time to fix be adjusted? Please provide your feedback in order to improve the content of this specific Repair Action and its association with the EFC that prompted this.'
              ),
              part: t(
                'Please provide your feedback in order to improve the content that follows this Part Number.'
              ),
            }[props.feedbackType]
          }
        </p>
        <p className="mt-1 text-lg leading-5">
          {
            {
              general: '',
              efc: t('Module: {{moduleName}}', { moduleName: moduleName }),
              repairAction: props.repairActionName,
              part: '',
            }[props.feedbackType]
          }
        </p>
        <p className="mt-1 text-base leading-5 text-steel">
          {
            {
              general: t(
                'Feedback for a specific EFC Code, Repair Action, or Part should be submitted using the feedback option available on those specific entries.'
              ),
              efc: props.efcDesciption,
              repairAction: props.repairActionDescription,
              part: props.partName,
            }[props.feedbackType]
          }
        </p>
      </>
    );
  };

  return (
    <>
      <Sheet
        isOpen={props.isOpen}
        onClose={props.handleClose}
        snapPoints={[620, 450, 60, 0]}
      >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit, handleError)}>
                <div className="align-center ml-4 flex max-h-max flex-col overflow-y-auto">
                  <div className="-mt-2 flex justify-between text-lg">
                    <Button
                      onClick={() => {
                        methods.reset();
                        props.handleClose();
                      }}
                      style={{ textTransform: 'none' }}
                      color="primary"
                      size="large"
                      sx={{ fontWeight: 700 }}
                    >
                      {t('Cancel')}
                    </Button>
                    <Button
                      color="primary"
                      disabled={!methods.formState.isValid}
                      type="submit"
                      size="large"
                      sx={{ fontWeight: 700 }}
                      style={{ textTransform: 'none' }}
                    >
                      {t('Send')}
                    </Button>
                  </div>
                  {renderTitle()}
                  <div className="mt-4 flex items-center">
                    <div className="text-lg font-semibold">
                      {t('Corrective action required')}?
                    </div>
                    <Controller
                      control={methods.control}
                      name="correctiveActionRequired"
                      defaultValue={false}
                      render={({ field: { onChange, value, ref } }) => {
                        return (
                          <FormControlLabel
                            label={value ? t('Yes') : t('No')}
                            labelPlacement="start"
                            name="correctiveActionRequired"
                            control={
                              <IOSSwitch onChange={onChange} inputRef={ref} />
                            }
                          />
                        );
                      }}
                    />
                  </div>
                  <div className="mt-1 text-base leading-5 text-steel">
                    {t(
                      'Select "Yes" if you are recommending that a change needs to be made by the administrators.'
                    )}
                  </div>
                  {props.feedbackType === 'repairAction' && (
                    <div className="mt-4 flex items-center">
                      <div className="text-lg font-semibold">
                        {t('Repair Action performed')}?
                      </div>
                      <Controller
                        control={methods.control}
                        name="repairActionPerformed"
                        defaultValue={true}
                        render={({ field: { onChange, value, ref } }) => {
                          return (
                            <FormControlLabel
                              label={value ? t('Yes') : t('No')}
                              labelPlacement="start"
                              name="repairActionPerformed"
                              control={
                                <IOSSwitch
                                  checked={value}
                                  onChange={onChange}
                                  inputRef={ref}
                                />
                              }
                            />
                          );
                        }}
                      />
                    </div>
                  )}
                  <div className="mt-4">
                    <div className="font-medium">{t('Comments')}</div>
                    <TextField
                      id="outlined-basic"
                      multiline
                      size="medium"
                      placeholder={t(
                        'Feedback is not mandatory.Please use English if possible. Feedback is used to improve the content in ACFS. Feedback can be shared with management to ensure quality. Administrators may contact individuals for additional information to help clarify any information needed to improve content or other business purposes.'
                      )}
                      minRows={2}
                      variant="outlined"
                      fullWidth
                      sx={{ paddingRight: '30px' }}
                      {...methods.register('comments', {
                        required: 'Comments are required',
                        pattern: {
                          value: /^$|.*\S+.*/,
                          message: t('Please enter some text'),
                        },
                      })}
                      name="comments"
                    />
                    {methods.formState.errors?.comments && (
                      <div className="text-tangerine">
                        {methods.formState.errors.comments.message}
                      </div>
                    )}
                  </div>
                  {props.feedbackType === 'repairAction' && (
                    <>
                      <div className="mt-4 flex">
                        <div className="mt-2 mr-4 font-medium">
                          {t('Time to fix (minutes)')}
                        </div>
                        <TextField
                          id="outlined-basic"
                          size="small"
                          variant="outlined"
                          type="number"
                          sx={{ width: 80 }}
                          {...methods.register('timeToFix', {
                            maxLength: {
                              value: 3,
                              message: t(
                                'Time to fix should not be more than 999'
                              ),
                            },
                            min: {
                              value: 0,
                              message: t('Please enter positive numbers only'),
                            },
                            pattern: {
                              value: /^[0-9]+$/,
                              message: t('Please enter numbers only'),
                            },
                          })}
                          name="timeToFix"
                        />
                      </div>
                      {methods.formState.errors?.timeToFix && (
                        <div className="text-tangerine">
                          {methods.formState.errors.timeToFix.message}
                        </div>
                      )}
                      <div className="mt-4 flex items-center">
                        <div className="font-medium">{t('Rating')}</div>
                        <div className="ml-4">
                          <StarRatingForm rating={0} formName="rating" />
                        </div>
                      </div>
                    </>
                  )}

                  {props.feedbackType !== 'part' && (
                    <>
                      <MultipleFileSelect name="photos" maxFiles={5} />
                    </>
                  )}
                </div>
              </form>
            </FormProvider>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
    </>
  );
}

export default FeedbackScreen;
function logFeedBack(props: PropsWithChildren<Props>, feedback: Feedback) {
  const logParams: LogParams[] = [
    {
      key: LogType.Page,
      value: LogType.Fb,
    },
    {
      key: LogType.FbType,
      value: props.feedbackType,
    },
    {
      key: LogType.Value,
      value: LogType.Comment,
    },
    {
      key: LogType.EfcCode,
      value: props.efcCode ?? '',
    },
    {
      key: LogType.RaId,
      value: props.repairActionId?.toString() ?? '',
    },
    {
      key: LogType.ServiceId,
      value: feedback?.taskActivityNumber ?? '',
    },
    {
      key: LogType.PartNumber,
      value: props.partNumber ?? '',
    },
    {
      key: LogType.PartName,
      value: props.partName ?? '',
    },
    {
      key: LogType.CorrectiveActionRequired,
      value: feedback?.correctiveActionRequired.toString(),
    },
    {
      key: LogType.EndpointId,
      value: feedback.endpointId,
    },
    {
      key: LogType.SerialNumber,
      value: feedback.serialNumber,
    },
  ];
  return logParams;
}
