const translation = {
  'You switched to {{ language }}': 'Sie haben zu {{ language }} gewechselt',
  'German': 'Deutsch',
  'Home': 'Home',
  'Search': 'Suche',
  'Saved': 'Gespeichert',
  'Me': 'Meine Seite',
  'Menu': 'Menü',
  'English': 'Englisch',
  'AllConnect Field Support': 'AllConnect Field Support',
  'Settings': 'Einstellungen',
  'Language': 'Sprache',
  'Saved Items': 'Gespeicherte Artikel',
  'My tasks': 'Meine Aufgaben',
  'Parts': 'Ersatzteile',
  'Saved items': 'Gespeicherte Artikel',
  'Notifications': 'Benachrichtigungen',
  'Help': 'Hilfe',
  'Feedback': 'Feedback',
  'Feedback Status': 'Feedback-Status',
  'Repair Feedback': 'Feedback zur Reparatur',
  'EFC Feedback': 'EFC Feedback',
  'General Feedback': 'Allgemeines Feedback',
  'Module Name': 'Name des Moduls',
  'EFC Code': 'EFC-Code',
  'Your Feedback': 'Ihr Feedback',
  'Field Feedback Requested': 'Feedback aus der Praxis erwünschti',
  'Level 4 to Review': 'Stufe 4 bis Überprüfung',
  'In Process by Level 4': 'In Bearbeitung bei Level 4',
  'Processed': 'Verarbeitet',
  'Logout': 'Ausloggen',
  'Add to equipment list': 'Zur System-Liste hinzufügen',
  'Download for offline': 'Offline herunterladen',
  'Repair Action': 'Reparaturmaßnahme',
  'Part': 'Ersatzteil',
  'Refreshed {{time}} min ago': 'Vor {{time}} min aktualisiert',
  'Recently viewed': 'Zuletzt angesehen',
  'View all': 'Alle ansehen',
  'Clear All': 'Alles löschen',
  'Expand all': 'Alle erweitern',
  'Collapse all': 'Alle schließen',
  'Search results': 'Suchergebnisse',
  'Repairs': 'Reparaturmaßnahmen',
  'Downloading content...': 'Herunterladen von Inhalten...',
  'Bookmarks': 'Lesezeichen',
  'Downloads': 'Downloads',
  'Recent': 'Jüngste',
  'Recent codes': 'Jüngste codes',
  'Saved codes': 'Gespeicherte Codes',
  'Updates': 'Aktualisierung',
  'Clear': 'Löschen',
  'Updated Content for {{item}}.': 'Aktualisierter Inhalt für {{item}}',
  '{{item}} has been saved': '{{item}} wurde gespeichert',
  '{{item}} could not be saved': '{{item}} konnte nicht gespeichert werden',
  '{{item}} has been removed from saved':
    '{{item}} wurde aus Gespeichert entfernt',
  '{{item}} could not be removed from saved':
    '{{item}} konnte nicht aus Gespeichert entfernt werden',
  'Difficulty': 'Schwierigkeit',
  'Repair time': 'Reparaturzeit',
  'min_one': '1 Minute',
  'min_other': '{{count}} Minuten',
  'min_zero': '0 Minuten',
  'Rating': 'Bewertung',
  'Thank you for the feedback': 'Danke für die Bewertung',
  'Not relevant': 'Nicht relevant',
  'Relevant': 'Relevant',
  'Fixed my issue': 'Mein Problem wurde behoben',
  'Leave feedback': 'Hinweis geben',
  'Save item': 'Artikel speichern',
  'Low': 'Niedrig',
  'Medium': 'Mittel',
  'High': 'Hoch',
  'This was relevant info': 'Information war relevant',
  'This was not relevant info': 'Information war nicht relevant',
  'Add to saved items': 'Zu gespeicherten Artikeln hinzufügen',
  'Remove from downloads': 'Aus Downloads entfernen',
  'Search AllConnect Field Support': 'Suche AllConnect Field Support',
  'Filters': 'Filtern',
  'Page not found': 'Seite nicht gefunden',
  'Return home': 'Zurück zum Home-Bildschirm',
  'Clear all bookmarks': 'Alle Lesezeichen löschen',
  'Search all EFC codes': 'Suche in allen EFC Codes',
  'Search all parts': 'Suche in allen Ersatzteilen',
  'Results ({{resultsCount}})': 'Ergebnisse ({{resultsCount}})',
  '{{item}} is already saved': '{{item}} bereits gespeichert',
  'Details': 'Details',
  'Systems': 'Systeme',
  'Equipment': 'Systeme',
  'Notes': 'Notizen',
  'Preventive Insights': 'Präventive Einblicke',
  'Service Insights': 'Service Einblicke',
  'Equipment Information': 'System-Information',
  'Equipment ID': 'System-ID',
  'Model': 'Modell',
  'Terminal ID': 'Terminal ID',
  'Device serial number': 'System Serialnummer',
  'EFC codes': 'EFC-Codes',
  'Steps': 'Schritte',
  'Module content': 'Inhalt Modul',
  'Repair action content': 'Inhalt Reparaturmaßnahme',
  'Fixed issue': 'Problem gelöst',
  'Part Number {{partId}} copied to the clipboard':
    'Ersatzteilnummer {{partId}} in Zwischenablage kopiert',
  'Relationship tree': 'Beziehungsbaum',
  'Potential substitutions': 'Mögliche Alternativen',
  'Loading': 'Wird geladen',
  'Removed from saved searches': 'Aus gespeicherten Suchanfragen gelöscht',
  'Added to saved searches': 'Zu gespeicherten Suchanfragen hinzugefügt',
  'All bookmarks cleared': 'Alle Lesezeichen gelöscht',
  'No bookmarks to clear': 'Keine löschbaren Lesezeichen vorhanden',
  'All recent items cleared': 'Alle neusten Einträge gelöscht',
  'No recent items to clear': 'Keine löschbaren neuen Einträge',
  'You have no recently view history.': 'Kein neuer Verlauf vorhanden',
  'Signing you in': 'Anmelden',
  'Signing you out': 'Abmelden',
  'Oops': 'Ups',
  'No results': 'Keine Ergebnisse',
  'URL': 'URL',
  'User Name': 'Nutzername',
  'ACFS App': 'ACFS App',
  'ACFS UI': 'ACFS UI',
  'Offline Data': 'Offline Daten',
  'Info': 'Info',
  'Repair': 'Reparatur',
  'RepairActionCount 1': '1 Reparaturmaßnahme',
  'RepairActionCount {{count}}': '{{count}} Reparaturmaßnahme',
  'RepairActionCount 0': '0 Reparaturmaßnahme',
  'All': 'Alle',
  'Sync': 'synchronisieren',
  'Last 30 days': 'Letzten 30 Tage',
  'Your feedback has been sent': 'Dein Feedback wurde versendet',
  'Failed to send your feedback': 'Dein Feedback konnte nicht versendet werden',
  'Comments are required': 'Kommentare sind erforderlich',
  'Comments should not be more than 100 characters':
    'Kommentare sollten nicht mehr als 100 Zeichen enthalten',
  'Max {{numOfPhotos}} photos can be uploaded':
    'Maximal können {{numOfPhotos}} Fotos hochgeladen werden',
  'Add this Task - {{taskNumber}}': 'Diese Aufgabe hinzufügen - {{taskNumber}}',
  "Other '{{inputValue}}'": "Andere '{{inputValue}}'",
  'Other (Type to add) / Blank': 'Andere (Type to add) / Unausgefüllt',
  'General feedback': 'Generelles Feedback',
  'Feedback: EFC code - {{efcCode}}': 'Feedback: EFC code - {{efcCode}}',
  'Module: {{moduleName}}': 'Modul: {{moduleName}}',
  'Feedback for a specific EFC Code, Repair Action, or Part should be submitted using the feedback option available on those specific entries.':
    'Feedback für bestimmte EFC Codes, Reparaturmaßnahmen oder Ersatzteile sollten über die Feedbackoption, die für diese spezifischen Einträge verfügbar ist, übermittelt werden',
  'Corrective action required': 'Korrekturmaßnahmen sind erforderlich',
  'Yes': 'Ja',
  'No': 'Nein',
  'Task number': 'Aufgabennummer',
  'Select or Type to add': 'Zum Hinzufügen auswählen oder eingeben',
  'Comments': 'Kommentare',
  'Delete': 'Löschen',
  'Open Camera': 'Kamera öffnen',
  'Upto {{maxFiles}} images can be selected':
    'Bis zu {{maxFiles}} Bilder können ausgewählt werden',
  'Cancel': 'Abbrechen',
  'Add': 'Hinzufügen',
  'Add equipment': 'Equipment hinzufügen',
  'Serial number': 'Seriennummer',
  'Scan the equipment code or enter the serial number to view the insights and details for that piece of equipment.':
    'Scannen Sie den Gerätecode oder geben Sie die Seriennummer ein, um Einblicke und Details zu diesem Gerät zu erhalten.',
  'Last synced: {{time}} min ago':
    'Zuletzt synchronisiert vor {{time}} Minuten',
  'Send': 'Senden',
  "Task Number must be provided if 'Corrective action required' is Yes":
    'Die Aufgabennummer muss angegeben werden, wenn "Abhilfemaßnahme erforderlich" auf Ja steht',
  'Time to fix (minutes)': 'Reperaturzeit (minutes)',
  'Time to fix should not be more than 999':
    'Die Reperaturzeit sollte nicht länger als 999 sein',
  'Please enter positive numbers only': 'Bitte nur positive Zahlen eingeben',
  'Please enter numbers only': 'Bitte nur Zahlen eingeben',
  'Clear filters': 'Filter zurücksetzen',
  'Show results': 'Ergebnisse anzeigen',
  'Your feedback has been submitted': 'Ihr Feedback wurde abgeschickt',
  'This EFC is related to an enrichment link that is currently active.Please let us know if the enrichment is relevant (appropriate action to perform).If you want to leave additional feedback select "Leave feedback"':
    'EFC hängt mit einem derzeitig aktiven enrichment Link zusammen. Bitte lassen Sie uns wissen, ob der enrichment Link relevant ist (geeignete Maßnahme zur Durchführung). Wenn Sie zusätzliches Feedback abgeben möchten, wählen Sie "Feedback abgeben"',
  'Products': 'Produkte',
  'Product Number {{productId}} copied to the clipboard':
    'Produktnummer {{productId}} wurde in die Zwischenablage kopiert',
  'View Groups': 'Gruppen anzeigen',
  'Search all products': 'Alle Produkte durchsuchen',
  'Curernt task': 'Aktuelle Aufgaben',
  'History': 'Historie',
  'No equipment data found for serial number {{number}}.':
    'Es wurden keine Equipmentdaten für die Seriennummer {{number}} gefunden',
  'Unable to retrieve equipment data for the serial number {{number}}.':
    'Gerätedaten für die Seriennummer {{number}} können nicht abgerufen werden',
  'Unable to retrieve equipment data. Falling back to offline data.':
    'Gerätedaten können nicht abgerufen werden. Rückgriff auf offline Daten.',
  'Unable to retrieve service task. Falling back to offline data.':
    'Serviceaufgabe kann nicht abgerufen werden. Rückgriff auf offline Daten.',
  'Please wait while data is being loaded in {{language}}':
    'Bitte warten Sie, während die Daten geladen werden {{language}}',
  'Errors': 'Fehler',
  'Tasks': 'Aufgaben',
  'History Parts': 'Historie Teile',
  'Unable to retrieve Service Task history.':
    'Historie der Serviceaufgaben kann nicht abgerufen werden',
  'Recommended Parts': 'Empfohlene Teile',
  'Service Task Details': 'Details zur Serviceaufgabe',
  'Task Number': 'Aufgabennummer',
  'Task Status': 'Aufgabenstatus',
  'Problem': 'Problem',
  'Task Type': 'Aufgabentyp',
  'Customer': 'Kunde',
  'Effective Date': 'Effektive Daten',
  'Device Description': 'Gerätebeschreibung',
  'Device Serial Number': 'Seriennummer des Geräts',
  'EndPoint Id': 'Endpunkt ID',
  'Service Task Notes {{length}}.': 'Hinweise zu Serviceaufgaben {{length}}.',
  'Part {{partId}} cannot be found in DNSpares data':
    'Ersatzteil {{partId}} kann nicht in den DNSpares Daten gefunden werden',
  'Number': 'Nummer',
  'Site Number': 'Standort Nummer',
  'Address': 'Adresse',
  'Contact Date': 'Kontakttermin',
  'Contact Name': 'Kontaktname',
  'Contact Email': 'Kontaktemail',
  'Contact Phone': 'Kontakttelefon',
  'Contract Hours': 'Kontaktstunden',
  'Site Messages:': 'Standortmeldungen:',
  'Type': 'Typ',
  'Product': 'Produkt',
  'Desc': 'Beschreibung',
  'Task Number should not be more than 30 characters':
    'Aufgabennumern sollten nicht mehr als 30 Zeichen beinhalten',
  'Select Photos': 'Ausgewählte Fotos',
  'Session Expired': 'Sitzung abgelaufen',
  'The current session has expired while offline. Please bring the device back online and select the Login button below to perform the login and take you back to the home screen.':
    'Die aktuelle Sitzung ist, während sie offline war, abgelaufen. Bitte bringen Sie das Gerät wieder online und klicken Sie unten auf Login, um die Anmeldung durchzuführen und zum Startbildschirm zurückzukehren.',
  'Login': 'Login',
  'Please provide your feedback in order to improve the content in the ACFS Site not related to a specific EFC or Repair Action.':
    'Bitte geben Sie uns Ihr Feedback, um den Inhalt der ACFS-Website zu verbessern, der sich nicht auf eine bestimmte EFC oder Reparaturaktion bezieht.',
  'Please provide your feedback in order to improve the content that follows this EFC Code.':
    'Bitte geben Sie uns Ihr Feedback, damit wir den Inhalt nach diesem EFC Code verbessern können.',
  'Is this Repair Action missing anything? For example, should the star rating or time to fix be adjusted? Please provide your feedback in order to improve the content of this specific Repair Action and its association with the EFC that prompted this.':
    'Fehlt in dieser Reparaturaktion etwas? Sollte zum Beispiel die Sternchenbewertung oder die Zeit bis zur Behebung angepasst werden? Bitte geben Sie uns Ihr Feedback, damit wir den Inhalt dieser spezifischen Reparaturmaßnahme und ihre Zuordnung zu EFC, die sie ausgelöst hat, verbessern können.',
  'Select "Yes" if you are recommending that a change needs to be made by the administrators.':
    'Wählen Sie "Ja", wenn Sie empfehlen, dass eine Änderung von den Administratoren vorgenommen werden muss.',
  'Feedback is not mandatory.Please use English if possible. Feedback is used to improve the content in ACFS. Feedback can be shared with management to ensure quality. Administrators may contact individuals for additional information to help clarify any information needed to improve content or other business purposes.':
    'Feedback ist nicht verpflichtent. Bitte verwenden Sie, wenn möglich, englisch. Das Feedback wird verwendet, um Inhalte in ACFS zu überarbeiten. Feedback kann mit dem Management geteilt werden, um die Qualität zu sichern. Die Administratoren können Personen um zusätzliche Informationen bitten, um Informationen zu klären, die zur Verbesserung des Inhalts oder für andere geschäftliche Zwecke benötigt werden.',
  'OfflineMessage':
    'Wenn in ACFS der offline Modus verwendet wird können manche Inhalte, aufgrund der Art der Inhalte, Häufigkeit der Updates und der Speicherbeschränkungen auf dem Endgerät, nicht angezeigt werden. Aufgrund dieser...',
  'OfflineMessage1':
    'Auf der Home Page und Me Page, zeigt der Meine Aufgaben Bereich nur offene, im Gerät verfügbare Aufgaben',
  'OfflineMessage2':
    'Auf gerätebezogenen Seiten (Geräte, Details, Teile) werden Geräte- und Serviceaufgabendaten anhand der im Gerät verfügbaren Daten angezeigt.',
  'OfflineMessage3':
    'Die Notizseite zeigt nur die Notizen der offenen Serviceaufgabe des Benutzers an, die in dem Gerät verfügbar sind, das dem Equipment entspricht.',
  'OfflineMessage4':
    'Die Funktion Ausrüstung hinzufügen auf der Me Page und Equipment Page funktioniert nicht, da die manuelle Ausrüstung nicht auf der Grundlage vom Benutzer angegebenen Seriennummer abgerufen werden kann.',
  'OfflineMessage5':
    'Die Bilder im Inhalt der App, wie z.B. EFC-Bilder, Bilder von Reparatur-Aktionsschritten, Bilder von Teilen werden nicht angezeigt, wenn der Benutzer offline ist. ',
  'Feedback: Repair # {{repairActionId}}':
    'Feedback: Reparatur # {{repairActionId}}',
  'Training': 'Training',
  'General Help': 'Generelle Hilfe',
  'Offline Disclaimer': 'Offline Haftungsausschluss',
  'Nothing here yet': 'Hier ist noch nichts',
  'Unable to retrieve tasks. Falling back to offline data.':
    'Aufgaben können nicht abegrufen werden. Rückgriff auf Offline-Daten.',
  'Category': 'Kategorie',
  'Family': 'Familie',
  'Skill level required': 'Qualifikationslevel erforderlich',
  'Sort by': 'Sortiert nach',
  'Subsystem': 'Subsystem',
  'Recent records': 'Aktuelle Rekorde',
  'Task - {{taskNumber}}': 'Aufgabe - {{taskNumber}}',
  'Add New': 'Neues hinzufügen',
  'Everywhere': 'Überall',
  'Keywords': 'Stichworte',
  'The details page contains content only when there is an active call assigned to the current user for this equipment.':
    'Die Detailseite enthält nur dann Inhalte, wenn dem aktuellen Nutzer für das Equipment ein aktiver Anruf zugewiesen ist.',
  'Categories': 'Kategorien',
  'Switching language to {{ language }}':
    'Sie haben zu {{ language }} gewechselt',
  'Data has been fetched in': 'Daten wurden abgerufen',
  'Failed to fetch data in': 'Daten konnten nicht abgerufen werden',
  'Switching language is done in 2 phases. The static data such as labels and headings will be switched immediately. The content data requires an online connection and can take up to a minute or more depending on your connection to refresh the new language choice.  Until content is downloaded the app will continue to show the previous language for content.':
    'Die Sprachumschaltung erfolgt in 2 Phasen. Die statischen Daten wie Beschriftungen und Überschriften werden sofort umgestellt. Die Inhaltsdaten erfordern eine Online-Verbindung und es kann je nach Verbindung bis zu einer Minute oder länger dauern, bis die neue Sprachauswahl aktualisiert wird. Bis der Inhalt heruntergeladen ist, zeigt die App weiterhin die vorherige Sprache für Inhalte an.',
  'Current': 'Aktuelle',
  'Training Content': 'Schulungsinhalte',
  'Repair actions': 'Reparaturmaßnahmen',
  'Module': 'Modul',
  'Preferences': 'Präferenzen',
  'Not yet implemented': 'Noch nicht implementiert',
  'Module(EFC only)': 'Modul (nur EFC)',
  'Francias': 'Französisch',
  'Polish': 'Polnisch',
  'Espanol': 'Spanisch',
  'Indonesian': 'Indonesisch',
  'Chinese': 'Chinesisch',
  'Thai': 'Thailändisch',
  'Vietnamese': 'Vietnamesisch',
  'Portuguese': 'Portugiesisch',
  'Turkish': 'Türkisch',
  'If no stock, replaces': 'Wenn kein Lagerbestand, ersetzt',
  'If no stock, replaced by': 'Wenn kein Lagerbestand, ersetzt durch',
  'Replaces': 'Ersetzt',
  'Replaced by': 'Ersetzt durch',
  'Repair Id': 'Reparatur-ID',
  'View Child Ids': 'Untergeordnete IDs anzeigen',
  'Device Id': 'Geräte-ID',
  'Equipment with serial number {{number}} added Succesfully.':
    'Geräte mit Seriennummer {{number}} erfolgreich hinzugefügt.',
  'Open eServices-Connect': 'Open eServices-Connect',
  'Other Service Functions': 'Other Service Functions',
  'mfg': 'HerNr',
  'Reporting System': 'Reporting System',
  'Update': 'Update',
  'No Endpoint': '„kein Endgerät“',
  'Repair Action performed': 'Reparaturmaßnahme durchgeführt',
  'Update: User names are now visible in task history. You’ll see names of users, and others will see yours. This change helps identify previous users, making it easier to contact them for further information if needed.':
    'Update: Benutzernamen sind jetzt im Aufgabenverlauf sichtbar. Sie sehen die Namen der Benutzer, und andere sehen Ihren Namen. Diese Änderung hilft dabei, frühere Benutzer zu identifizieren, was es einfacher macht, sie bei Bedarf für weitere Informationen zu kontaktieren.',
  'The ACDE Knowledgebase content is currently being downloaded to this device.  We apologize for the inconvenience.  The page will automatically redirect when the content load is complete.':
    'Der ACDE Knowledgebase-Inhalt wird auf das Gerät heruntergeladen. Entschuldigung für die Unannehmlichkeiten. Die Seite wird automatisch weitergeleitet, wenn das Laden des Inhalts abgeschlossen ist.',
  'We apologize for the inconvenience.  The link used is not a valid location in ACFS.':
    'Entschuldigung für die Unannehmlichkeiten.  Der verwendete Link ist kein gültiger Speicherort in ACFS.',
  'The selected endpoint on the Repair Action Page does not match one of the endpoint of the active service task assigned. Select OK to continue. Cancel to change.':
    'Das ausgewählte System auf der ’Reparaturmaßnahme’-Seite ist nicht identisch mit dem System, das im Service Task angegeben ist. OK um fortzufahren. Abbrechen um ein anderes System zu wählen.',
  'Select “Yes” if you are recommending that a new Repair Action needs to be created by the administrators.':
    'Wählen Sie "Ja", wenn Sie empfehlen, dass eine neue Reparaturaktion von den Administratoren erstellt werden muss.',
  'Select this repair action template to specify the action completed':
    'Wählen Sie diese Vorlage für einen Reparaturvorgang, um den abgeschlossenen Vorgang anzugeben.',
  'Custom Repair Performed': 'Kundenspezifische Reparatur durchgeführt',
  'Submit': 'Einreichen',
  'Needs reviewed for new RA': 'Bedarf für neuen RA geprüft',
  'Title': 'Titel',
  'Title of repair': 'Titel der Reparatur',
  'Description of repair': 'Beschreibung der Reparatur',
  'Duration to complete repair (minutes)':
    'Dauer bis zum Abschluss der Reparatur (Minuten)',
  '3 or more Characters are required for the search.':
    '3 oder mehr Zeichen sind für die Suche erforderlich.',
  'Title is required': 'Titel ist erforderlich',
  'Title should not be more than 200 characters':
    'Der Titel sollte nicht länger als 200 Zeichen sein',
  'Details is required': 'Details sind erforderlich',
  'Switching language is done in 2 phases. The static data such as labels and headings will be switched immediately. The content data requires an online connection and can take up to a minute or more depending on your connection to refresh the new language choice. Until content is downloaded the app will continue to show the previous language for content.  Recently viewed and Saved items will be the last to update and can take up to an additional minute.':
    'Die Sprachumstellung erfolgt in 2 Phasen. Die statischen Daten wie Beschriftungen und Überschriften werden sofort umgestellt. Die Inhaltsdaten erfordern eine Online-Verbindung und es kann je nach Verbindung bis zu einer Minute oder länger dauern, bis die neue Sprachauswahl aktualisiert wird. Bis der Inhalt heruntergeladen ist, zeigt die App weiterhin die vorherige Sprache für den Inhalt an.  Kürzlich angesehene und gespeicherte Elemente werden als letztes aktualisiert und können bis zu einer weiteren Minute dauern.',
  'No results Or Multiple Results, please narrow your search':
    'Keine Ergebnisse oder mehrere Ergebnisse, bitte grenzen Sie Ihre Suche ein',
  'Enter the serial number to view the insights and details for that piece of equipment.':
    'Geben Sie die Seriennummer ein, um Einblicke und Details zu diesem Gerät zu erhalten.',
  'Open': 'Öffnen Sie',
  'Interrupt': 'Unterbrechung',
  'Incomplete': 'Unvollständig',
  'Closed': 'Geschlossen',
  'Cancelled': 'Abgesagt',
  'Scanned successfully, Redirecting to the scanned link':
    'Erfolgreich gescannt, Weiterleitung zum gescannten Link',
  'Search Serial number': 'Suche Seriennummer',
  'No System is Selected': 'Kein System ist ausgewählt',
  'Cancelled Tasks': 'Abgebrochene Aufgaben',
  'Allowed': 'Erlaubt',
  'Not Allowed': 'Nicht erlaubt',
  'Open Task UserId': 'Aufgabe öffnen UserId',
  'Observations': 'Beobachtungen',
  'GroupName or UserID': 'Gruppenname oder UserID',
  'AI Summary': 'AI Zusammenfassung',
  'Chronic / Worst Performer Details':
    'Chronische/schlechteste Leistung Details',
  'Triage Notes': 'Triage-Notizen',
  'Search By': 'Suche nach',
  'Serial Number': 'Seriennummer',
  'Tag': 'Etikett',
  'Temporarily add equipment': 'Vorübergehend Ausrüstung hinzufügen',
  'System': 'System',
  'Machine Name': 'Maschinenname',
  'No data is available': 'Es sind keine Daten verfügbar.',
  'Connections': 'Verbindungen',
  'Non-Connected': 'Nicht-verbundene',
  'Part Recommendations': 'Teil Empfehlungen',
  'Please wait for the daily update.  The page will automatically refresh when completed.':
    'Bitte warten Sie auf die tägliche Aktualisierung.  Die Seite wird nach Fertigstellung automatisch aktualisiert.',
  'Install Date': 'Datum der Installation',
  'ACDE KB daily update is currently in progress. Part navigation is not available. RepairAction indicator is also not available. Please try again when the content has been downloaded.':
    'Die tägliche Aktualisierung von ACDE KB ist derzeit im Gange. Die Teilenavigation ist nicht verfügbar. Der RepairAction-Indikator ist ebenfalls nicht verfügbar. Bitte versuchen Sie es erneut, wenn der Inhalt heruntergeladen wurde.',
  'No Equipment data available for the Endpoint {{endPointId}}.':
    'Keine Ausrüstungsdaten für den Endpunkt {{endPointId}} verfügbar.',
  'Duplicate endpoint has been identified. Historical EFCs may have come from multiple endpoints. Caution should be taken when reviewing the history of this endpoints. Validate that Harvester is setup properly with the correct endpoint registration.':
    'Es wurde ein doppelter Endpunkt identifiziert. Historische WFAs können von mehreren Endpunkten stammen. Seien Sie vorsichtig, wenn Sie den Verlauf dieses Endpunkts überprüfen. Überprüfen Sie, ob Harvester ordnungsgemäß mit der richtigen Endpunktregistrierung eingerichtet ist.',
  'Duplicate Endpoint': 'Endpunkt duplizieren',
  'Inventory': 'Bestandsaufnahme',
  'Entitlements': 'Berechtigungen',
  'Green': 'Grün',
  'Yellow': 'Gelb',
  'Red': 'Rot',
  'Empty': 'Leere',
  'Entitlement is connected and sending data as expected.':
    'Das Gerät ist angeschlossen und sendet Daten wie erwartet.',
  'Entitlement is connected, but not sending data as expected.  Review the detailed message on the entitlement details page. Click on card to get details on how to resolve.':
    'Die Berechtigung ist verbunden, sendet aber nicht wie erwartet Daten.  Überprüfen Sie die detaillierte Meldung auf der Seite mit den Berechtigungsdetails. Klicken Sie auf die Karte, um Details zur Lösung des Problems zu erhalten.',
  'Entitlement is expected, but is not connected and no data.  Click on card to get details on how to resolve.':
    'Berechtigung wird erwartet, ist aber nicht verbunden und keine Daten.  Klicken Sie auf die Karte, um Details zur Lösung des Problems zu erhalten.',
  'Entitlement does not send information as to the current status. No action is required.':
    'Die Berechtigung sendet keine Informationen über den aktuellen Status. Es ist keine Aktion erforderlich.',
  'Currently offline, endpoint will be removed when connected.':
    'Derzeit offline, Endpunkt wird entfernt, wenn er verbunden wird.',
  'Currently offline, usertag will update when connected.':
    'Derzeit offline, Usertag wird bei Verbindung aktualisiert.',
  'Disconnected': 'Ontkoppeld',
  'A disconnected system was previously connected and sending data to ACDE. However, data transmission has now stopped, exceeding the expected timeframe for this connection. Please refer to the ‘Last Data’ field to determine when data was last received.':
    "Een losgekoppeld systeem was eerder verbonden en verzond gegevens naar ACDE. De gegevensoverdracht is nu echter gestopt, waardoor de verwachte tijd voor deze verbinding is overschreden. Raadpleeg het vel'Laatste gegevens' om te bepalen wanneer voor het laatst gegevens zijn ontvangen.",
  'Software Updated': 'Aktualisierte Software',
  'Software': 'Software',
  'comoponents updated': 'Komponenten aktualisiert',
  'component updated': 'Komponente aktualisiert',
  'Last Data': 'Letzte Daten',
  'First Data': 'Erste Daten',
  'Data Origin': 'Datenursprung',
  'Avg Data Size': 'Durchschnittliche Datengröße',
  'Transport': 'Transport',
  'Version': 'Version',
  'Last Update': 'Letzte Aktualisierung',
  'Filter by Software': 'Nach Software filtern',
  'Filter by Version': 'Nach Version filtern',
  'Filter by Last Update': 'Nach letzter Aktualisierung filtern',
  'Please enter some text': 'Bitte geben Sie einen Text ein',
  'Serial Number already exists.': 'Die Seriennummer ist bereits vorhanden.',
  'Managed Services': 'Verwaltete Dienste',
  'True': 'Wahr',
};

export default translation;
