const translation = {
  'You switched to {{ Language }}': 'Cambiaste a {{ language }}',
  'Spanish': 'Español',
  'Home': 'Inicio',
  'Search': 'Buscar',
  'Saved': 'Grabado',
  'Me': 'Mi página',
  'Menu': 'Menú',
  'English': 'Inglés',
  'AllConnect Field Support': 'AllConnect Field Support',
  'Settings': 'Ajustes',
  'Language': 'Idioma',
  'Saved Items': 'Artículos grabados',
  'My tasks': 'Mis tareas',
  'Parts': 'Piezas',
  'Saved items': 'Artículos guardados',
  'Notifications': 'Notificaciones',
  'Help': 'Ayuda',
  'Feedback': 'Comentarios',
  'Feedback Status': 'Estado de los comentarios',
  'Repair Feedback': 'Reparaciones',
  'EFC Feedback': 'EFC Comentarios',
  'General Feedback': 'Comentarios generales',
  'Module Name': 'Nombre del módulo',
  'EFC Code': 'Código CEF',
  'Your Feedback': 'Su opinión',
  'Field Feedback Requested': 'Comentarios solicitados',
  'Level 4 to Review': 'Nivel 4 a Revisión',
  'In Process by Level 4': 'En proceso por nivel 4',
  'Processed': 'Procesado',
  'Logout': 'Cerrar sesión',
  'Add to equipment list': 'Agregar a la lista del sistema',
  'Download for offline': 'Descargar para uso offline',
  'Repair Action': 'Acción de reparación',
  'Repair action': 'Acción de reparación',
  'Part': 'Pieza',
  'Refreshed {{time}} min ago': 'Actualizado hace {{time}} min',
  'Recently viewed': 'Visto recientemente',
  'View all': 'Ver todo',
  'Clear All': 'Borrar todo',
  'Expand all': 'Expandir todo',
  'Collapse all': 'Contrair todo',
  'Search results': 'Resultados de busqueda',
  'Repairs': 'Medidas de reparación',
  'Downloading content...': 'Descargando contenido...',
  'Bookmarks': 'Enlaces',
  'Downloads': 'Descargas',
  'Recent': 'Reciente',
  'Recent codes': 'Codes recientes',
  'Saved codes': 'Codes grabados',
  'Updates': 'Actualizaciones',
  'Clear': 'Borrar',
  'Updated Content for {{item}}.': 'Contenido actualizado {{item}}',
  '{{item}} has been saved': '{{item}} Grabado',
  '{{item}} could not be saved': '{{item}} no fue grabado',
  '{{item}} has been removed from saved': '{{item}} borrado de los grabados',
  '{{item}} could not be removed from saved':
    '{{item}} no fue borrado de grabados',
  'Difficulty': 'Dificultad',
  'Repair time': 'Tiempo de reparación',
  'min_one': '1 min',
  'min_other': '{{count}} mins',
  'min_zero': '0 min',
  'Rating': 'Clasificación',
  'Thank you for the feedback': 'Gracias por la retroalimentación',
  'Not relevant': 'No relevante',
  'Relevant': 'Relevante',
  'Fixed my issue': 'Mi problema fue resuelto',
  'Leave feedback': 'Deja un comentario',
  'Save item': 'Guardar artículo',
  'Low': 'Bajo',
  'Medium': 'Medio',
  'High': 'Alto',
  'This was relevant info': 'Esta información fue relevante',
  'This was not relevant info': 'Esta información no fue relevante',
  'Add to saved items': 'Agregar a artículos guardados',
  'Remove from downloads': 'Borrar de descargas',
  'Search AllConnect Field Support': 'Busca AllConnect Field Support',
  'Filters': 'Filtros',
  'Page not found': 'Página no encontrada',
  'Return home': 'Volver a inicio',
  'Clear all bookmarks': 'Borrar todos los enlaces',
  'Search all EFC codes': 'Buscar en todos los Códigos EFC',
  'Search all parts': 'Buscar en todas las piezas',
  'Results ({{resultsCount}})': 'Resultados ({{resultsCount}})',
  '{{item}} is already saved': '{{item}} ya fue guardado',
  'Details': 'Detalles',
  'Equipment': 'Sistema',
  'Notes': 'Notas',
  'Preventive Insights': 'Visión Preventiva',
  'Service Insights': 'Conocimientos del servicio',
  'Equipment Information': 'Información del sistema',
  'Equipment ID': 'ID del sistema',
  'Model': 'Modelo',
  'Terminal ID': 'ID del Terminal',
  'Device serial number': 'Número de Serie del producto',
  'EFC codes': 'Códigos EFC',
  'Steps': 'Pasos',
  'Module content': 'Contenido del módulo',
  'Repair action content': 'Contenido de la acción de reparación',
  'Fixed issue': 'Problema resuelto',
  'Part Number {{partId}} copied to the clipboard':
    'Número de pieza {{partId}} copiado al portapapeles',
  'Relationship tree': 'Árbol de relaciones',
  'Potential substitutions': 'Potencial de sustituciones',
  'Loading': 'Cargando',
  'Removed from saved searches': 'Borrado de busquedas grabadas',
  'Added to saved searches': 'Agregado a busquedas grabadas',
  'All bookmarks cleared': 'Todos los enlaces borrados',
  'No bookmarks to clear': 'No hay enlaces para borrar',
  'All recent items cleared': 'Todos los recientes enlaces borrados',
  'No recent items to clear': 'No hay enlaces recientes para borrar',
  'You have no recently view history.': 'No tiene historial reciente',
  'Switching language is done in 2 phases. The static data such as labels and headings will be switched immediately. The content data requires an online connection and can take up to a minute or more depending on your connection to refresh the new language choice.  Until content is downloaded the app will continue to show the previous language for content.':
    'El cambio de idioma se realiza en 2 fases. Los datos estáticos, como etiquetas y encabezados, se cambian inmediatamente. Los datos de contenido requieren una conexión de internet y dependiendo de su conexión, puede tardar hasta un minuto o más en actualizar el idioma elegido.  Hasta que se descargue el contenido, la aplicación seguirá mostrando el idioma anterior.',
  'Signing you in': 'Iniciando sesión',
  'Signing you out': 'Cerrando sesión',
  'Oops': '¡Uy!',
  'Switching language to {{ language }}': 'Cambiando idioma a {{ language }}',
  'Data has been fetched in': 'Los datos se han obtenido',
  'Failed to fetch data in': 'Los datos no se han obtenido',
  'No results': 'Sin resultados',
  'URL': 'URL',
  'User Name': 'Nombre de usuario',
  'ACFS App': 'ACFS-App',
  'ACFS UI': 'ACFS-UI',
  'Offline Data': 'Datos offline',
  'Info': 'Información',
  'Repair': 'Reparación',
  'RepairActionCount 1': '1 Acción de reparación',
  'RepairActionCount {{count}}': '{{count}} Acciones de reparación',
  'RepairActionCount 0': '0 Acciones de reparación',
  'All': 'Todo',
  'Sync': 'Sincr',
  'Last 30 days': 'Últimos 30 dias',
  'Not yet implemented': 'Sin implementar',
  'Categories': 'Categorias',
  'Repair actions': 'Acciones de reparación',
  'Module': 'Módulos',
  'Preferences': 'Preferencias',
  'Service Manuals': 'Manuales de servicio',
  'Service Bulletins': 'Boletín de servicio',
  'FCOs': 'FCOs',
  'FAQs': 'FAQs',
  'Training Content': 'Contenido del entrenamiento',
  'Your feedback has been sent': 'Su sugerencia a sido enviada',
  'Failed to send your feedback': 'Su sugerencia no ha sido enviada',
  'Comments are required': 'Se requiere comentario',
  'Comments should not be more than 100 characters':
    'El comentario no debe superar los 100 caracteres',
  'Max {{numOfPhotos}} photos can be uploaded':
    'Max {{numOfPhotos}} fotos pueden ser cargadas',
  'Add this Task - {{taskNumber}}': 'Adiciona esta tarea - {{taskNumber}}',
  "Other '{{inputValue}}'": "Otro '{{inputValue}}'",
  'Other (Type to add) / Blank': 'Otro (Type to add) / Blank',
  'General feedback': 'Sugerencia general',
  'Feedback: EFC code - {{efcCode}}': 'Sugerencia: EFC code - {{efcCode}}',
  'Module: {{moduleName}}': 'Módulo: {{moduleName}}',
  'Feedback for a specific EFC Code, Repair Action, or Part should be submitted using the feedback option available on those specific entries.':
    'Las sugerencias para un EFC-Code, acción de reparación o una pieza específica deben enviarse utilizando la opción de sugerencia disponible en la pantalla correspondiente.',
  'Corrective action required': 'Sugerencia de corrección',
  'Yes': 'Si',
  'No': 'No',
  'Task number': 'Número de tarea',
  'Select or Type to add': 'Selecciona o escribe para adicionar',
  'Comments': 'Comentarios',
  'Delete': 'Borrar',
  'Open Camera': 'Abrir camera',
  'Upto {{maxFiles}} images can be selected':
    'Hasta {{maxFiles}} imágenes pueden ser seleccionadas',
  'Cancel': 'Cancelar',
  'Add': 'Adicionar',
  'Add equipment': 'Adiciona sistema',
  'Serial number': 'Número de serie',
  'Scan the equipment code or enter the serial number to view the insights and details for that piece of equipment.':
    'Escanee el código del sistema o introduzca el número de serie para ver los conocimientos y detalles de ese sistema.',
  'Last synced: {{time}} min ago': 'Última sincronización: hace {{time}} min',
  'Send': 'Enviar',
  "Task Number must be provided if 'Corrective action required' is Yes":
    'Proporcione un número de tarea en caso requiera una acción correctiva',
  'Time to fix (minutes)': 'Tiempo de reparación (minutes)',
  'Time to fix should not be more than 999':
    'El tiempo de reparación no deberia superar 999',
  'Please enter positive numbers only': 'Por favor escriba números positivos',
  'Please enter numbers only': 'Por favor escriba solo números',
  'Clear filters': 'Borrar filtros',
  'Show results': 'Ver resultados',
  'Your feedback has been submitted': 'Su sugerencia fue enviada',
  'This EFC is related to an enrichment link that is currently active.Please let us know if the enrichment is relevant (appropriate action to perform).If you want to leave additional feedback select "Leave feedback"':
    'Este EFC está vinculado a un enlace de enriquecimiento que está actualmente activo.Por favor, háganos saber si el enriquecimiento es relevante (indica la acción apropiada).Si desea dejar comentarios adicionales, seleccione "Dejar sugerencia"',
  'Products': 'Productos',
  'Product Number {{productId}} copied to the clipboard':
    'Número de producto copiado {{productId}} para el portapapeles',
  'View Groups': 'Ver grupos',
  'Search all products': 'Busca en todos los productos',
  'Current': 'Actual',
  'History': 'Historial',
  'No equipment data found for serial number {{number}}.':
    'No se encontraron datos del sistema para este número de serie {{number}}.',
  'Unable to retrieve equipment data for the serial number {{number}}.':
    'Datos de sistema para este número de serie {{number}} no disponibles',
  'Unable to retrieve equipment data. Falling back to offline data.':
    'Datos del sistema no disponibles. Volviendo a los datos offline.',
  'Unable to retrieve service task. Falling back to offline data.':
    'Tarea de servicio no disponible. Volviendo a los datos offline',
  'Please wait while data is being loaded in {{language}}':
    'Por favor esperar mientras los datos en {{language}} se cargan',
  'Errors': 'Errores',
  'Tasks': 'Tareas',
  'History Parts': 'Historial de piezas',
  'Unable to retrieve Service Task history.':
    'Historial de tarea no disponible',
  'Recommended Parts': 'Piezas recomendadas',
  'Service Task Details': 'Detalles de tarea de servicio',
  'Task Number': 'Número de tarea',
  'Task Status': 'Estado de tarea',
  'Problem': 'Problema',
  'Task Type': 'Tipo de tarea',
  'Customer': 'Cliente',
  'Effective Date': 'Fecha efectiva',
  'Device Description': 'Descripción del producto',
  'Device Serial Number': 'Número de serie del producto',
  'EndPoint Id': 'Endpoint Id',
  'Service Task Notes {{length}}.': 'Apuntes de tarea de servicio {{length}}.',
  'Part {{partId}} cannot be found in DNSpares data':
    'La pieza {{partId}} no fue encontrada en DNSpares',
  'Number': 'Número',
  'Site Number': 'Número del sitio',
  'Address': 'Direccón',
  'Contact Date': 'Fecha de contacto',
  'Contact Name': 'Nombre de contacto',
  'Contact Email': 'Email de contacto',
  'Contact Phone': 'Teléfono de contacto',
  'Contract Hours': 'Horas de contrato',
  'Site Messages:': 'Mensages del sitio:',
  'Type': 'Tipo',
  'Product': 'Producto',
  'Desc': 'Descripción',
  'Task Number should not be more than 30 characters':
    'El número de tarea no debe superar los 30 caracteres',
  'Select Photos': 'Selecciona fotos',
  'Session Expired': 'Sesión expirada',
  'The current session has expired while offline. Please bring the device back online and select the Login button below to perform the login and take you back to the home screen.':
    'La sesión actual ha expirado mientras estaba offline. Vuelva a conectar el dispositivo y seleccione el botón de abajo Iniciar sesión para volver a la pantalla de inicio.',
  'Login': 'Iniciar sesión',
  'Please provide your feedback in order to improve the content in the ACFS Site not related to a specific EFC or Repair Action.':
    'Por favor escriba aqui su sugerencia de mejora para la aplicación ACFS no incluir en esta sugerencia un EFC-Code o acción de reparación especificos',
  'Please provide your feedback in order to improve the content that follows this EFC Code.':
    'Por favor escriba aqui su sugerencia para mejorar el contenido de este EFC-Code en concreto',
  'Is this Repair Action missing anything? For example, should the star rating or time to fix be adjusted? Please provide your feedback in order to improve the content of this specific Repair Action and its association with the EFC that prompted this.':
    '¿Le falta algo a esta acción de reparación? Por ejemplo, ¿debería ajustarse la clasificación de las estrellas o el tiempo de reparación? Por favor, envíenos sus comentarios para mejorar el contenido de esta Acción de Reparación en concreto y su asociación con el EFC que la sugiere.',
  'Select "Yes" if you are recommending that a change needs to be made by the administrators.':
    'Seleccione "Sí" si recomienda a los administradores hacer un cambio.',
  'Feedback is not mandatory.Please use English if possible. Feedback is used to improve the content in ACFS. Feedback can be shared with management to ensure quality. Administrators may contact individuals for additional information to help clarify any information needed to improve content or other business purposes.':
    'Las sugerencia no es obligatoria. Por favor, si es posible escriba en inglés. Las sugerencias se utilizan para mejorar el contenido de ACFS. La información será compartida con los expertos y podria ser contactado por los administradores para proporcionar información adicional para mejorar el contenido o otros propositos.',
  'OfflineMessage':
    ' Cuando ACFS está offline, hay algunos contenidos que no estarán disponibles debido a la naturaleza de los mismos, a la frecuencia de las actualizaciones y a las restricciones de almacenamiento en el dispositivo final.  Debido a esas...',
  'OfflineMessage1':
    'En las páginas Inicio y Mi página, la sección Mis tareas sólo mostrará las tareas abiertas disponibles.',
  'OfflineMessage2':
    'En las páginas relacionadas con el sistema (Sistema, Detalles, Piezas), Los datos del sistema y tarea de servicio se mostrarán según la disponibilidad en el dispositivo.',
  'OfflineMessage3':
    'La página de Notas sólo mostrará los comentarios de las tareas de servicio abiertas que estén disponibles en el dispositivo correspondiente al sistema.',
  'OfflineMessage4':
    'La funcionalidad Agregar sistema en las páginas no funcionará porque el número de serie proporcionado por el usuario no puede ser encontrado.',
  'Feedback: Repair # {{repairActionId}}':
    'Sugerencia: Reparación # {{repairActionId}}',
  'Training': 'Entrenamiento',
  'General Help': 'Ayuda general',
  'Offline Disclaimer': 'Offline Disclaimer',
  'Nothing here yet': 'Todavía no hay nada',
  'Unable to retrieve tasks. Falling back to offline data.':
    'Imposible recuperar tareas. Volviendo a los datos offline',
  'Category': 'Categoría',
  'Family': 'Familia',
  'Skill level required': 'Nivel de capacidad requerido',
  'Sort by': 'Ordenar por',
  'Subsystem': 'Subsistema',
  'Recent records': 'Registros recientes',
  'Systems': 'Sistemas',
  'Task - {{taskNumber}}': 'Tarea - {{taskNumber}}',
  'Add New': 'Añadir nuevo',
  'Everywhere': 'En todas partes',
  'Keywords': 'Palabras clave',
  'The details page contains content only when there is an active call assigned to the current user for this equipment.':
    'La página de detalles contiene contenido sólo cuando hay una llamada activa asignada al usuario actual para este equipo.',
  'OfflineMessage5':
    'Las imágenes en el contenido de la aplicación como imagenes de EFC, de piezas e imagenes de los pasos de las acciones de reparación no se mostrarán si el usuario está desconectado',
  'Module(EFC only)': 'Módulo (sólo EFC)',
  'German': 'Alemán',
  'Francias': 'Francés',
  'Polish': 'Polaco',
  'Indonesian': 'Indonesio',
  'Chinese': 'Chino',
  'Thai': 'Tailandés',
  'Vietnamese': 'Vietnamita',
  'Portuguese': 'Portugués',
  'Turkish': 'Turco',
  'If no stock, replaces': 'Si no hay stock, reemplaza',
  'If no stock, replaced by': 'Si no hay existencias, sustitúyalas por',
  'Replaces': 'Reemplaza',
  'Replaced by': 'Sustituido por',
  'Repair Id': 'ID de reparación',
  'View Child Ids': '	Ver Child Ids',
  'Device Id': 'ID del producto',
  'Equipment with serial number {{number}} added Succesfully.':
    '	Sistema con número de serie {{number}} agregado con éxito.',
  'mfg': 'Número de fabricación',
  'Reporting System': 'Sistema de reporte',
  'Update': 'actualización',
  'No Endpoint': 'Sin punto final',
  'Repair Action performed': 'Acción de reparación ejecutada',
  'Update: User names are now visible in task history. You’ll see names of users, and others will see yours. This change helps identify previous users, making it easier to contact them for further information if needed.':
    'Actualización: Los nombres de usuario ahora son visibles en el historial de tareas. Verás los nombres de otros usuarios, y ellos verán el tuyo. Este cambio ayuda a identificar a usuarios anteriores, facilitando el contacto con ellos para obtener más información si es necesario.',
  'The ACDE Knowledgebase content is currently being downloaded to this device.  We apologize for the inconvenience.  The page will automatically redirect when the content load is complete.':
    'El contenido de la base de conocimiento de ACDE está siendo descargado en este dispositivo. Disculpe el inconveniente. La página se redirigirá automáticamente cuando se complete la descarga del contenido.',
  'We apologize for the inconvenience.  The link used is not a valid location in ACFS.':
    'Disculpe el inconveniente. El enlace utilizado no es una página válida en ACFS.',
  'The selected endpoint on the Repair Action Page does not match one of the endpoint of the active service task assigned. Select OK to continue. Cancel to change.':
    'El punto final seleccionado en la Página Acción de reparación no coincide con ninguno de los puntos finales en la tarea activa asignada.Seleccione aceptar para continuar. Cancelar para cambiar.',
  'Select “Yes” if you are recommending that a new Repair Action needs to be created by the administrators.':
    'Seleccione "Sí" si quiere recomendar a los administradores la creación de una Acción de reparación nueva.',
  'Select this repair action template to specify the action completed':
    'Seleccione esta plantilla de acción de reparación para especificar la acción desarrollada.',
  'Custom Repair Performed': 'Especifique la reparación realizada',
  'Submit': 'Enviar',
  'Needs reviewed for new RA': 'Necesidades reconocidas para la nueva AR',
  'Title': 'Título',
  'Title of repair': 'Título de la reparación',
  'Description of repair': 'Descripción de la reparación',
  'Duration to complete repair (minutes)':
    'Duración para completar la reparación (minutos)',
  '3 or more Characters are required for the search.':
    'Se requieren 3 o más caracteres para la búsqueda.',
  'Title is required': 'Se requiere título',
  'Title should not be more than 200 characters':
    'El título no debe tener más de 200 caracteres',
  'Details is required': 'Se requieren detalles',
  'Switching language is done in 2 phases. The static data such as labels and headings will be switched immediately. The content data requires an online connection and can take up to a minute or more depending on your connection to refresh the new language choice. Until content is downloaded the app will continue to show the previous language for content.  Recently viewed and Saved items will be the last to update and can take up to an additional minute.':
    'El cambio de idioma se realiza en 2 fases. Los datos estáticos, como etiquetas y encabezados, se cambiarán inmediatamente. Los datos de contenido requieren una conexión en línea y pueden tardar hasta un minuto o más, dependiendo de su conexión. Hasta que se descargue el contenido, la aplicación seguirá mostrando el contenido en el idioma anterior. Los elementos guardados y vistos recientemente serán los últimos en actualizarse y podrían tardar adicionalmente un minuto o más.',
  'No results Or Multiple Results, please narrow your search':
    'Ningún resultado o hay numerosos resultados, por favor reduzca su búsqueda.',
  'Enter the serial number to view the insights and details for that piece of equipment.':
    'Introduzca el número de serie para ver la perspicacia y detalles de esta pieza del equipo.',
  'Open': 'Abrir',
  'Interrupt': 'Interrumpir',
  'Incomplete': 'Incompleto',
  'Closed': 'Cerrado',
  'Cancelled': 'Cancelado',
  'Scanned successfully, Redirecting to the scanned link':
    'Escaneado exitoso, redirigiendo al enlace escaneado',
  'Search Serial number': 'Buscar Número de serie',
  'No System is Selected': 'No se ha seleccionado ningún sistema',
  'Cancelled Tasks': 'Tareas canceladas',
  'Allowed': 'Permitido',
  'Not Allowed': 'No permitido',
  'Open Task UserId': 'Tarea abierta UserId',
  'Observations': 'Observaciones',
  'GroupName or UserID': 'NombreGrupo o IDUsuario',
  'AI Summary': 'Resumen de la IA',
  'Chronic / Worst Performer Details':
    'Crónica / Detalles de los peores resultados',
  'Triage Notes': 'Notas de triaje',
  'Search By': 'Buscar por',
  'Serial Number': 'Número de serie',
  'Tag': 'Etiqueta',
  'Temporarily add equipment': 'Añadir equipos temporalmente',
  'System': 'Sistema',
  'Machine Name': 'NombreMáquina',
  'No data is available': 'No se dispone de datos',
  'Connections': 'Conexiones',
  'Non-Connected': 'Sin conexión',
  'Part Recommendations': 'Parte Recomendaciones',
  'Please wait for the daily update.  The page will automatically refresh when completed.':
    'Espere a la actualización diaria.  La página se actualizará automáticamente cuando se complete.',
  'Install Date': 'Fecha de instalación',
  'ACDE KB daily update is currently in progress. Part navigation is not available. RepairAction indicator is also not available. Please try again when the content has been downloaded.':
    'La actualización diaria de ACDE KB está en curso. La navegación por piezas no está disponible. El indicador RepairAction tampoco está disponible. Por favor, inténtelo de nuevo cuando el contenido se haya descargado.',
  'No Equipment data available for the Endpoint {{endPointId}}.':
    'No hay datos de equipamiento disponibles para el Endpoint {{endPointId}}.',
  'Duplicate endpoint has been identified. Historical EFCs may have come from multiple endpoints. Caution should be taken when reviewing the history of this endpoints. Validate that Harvester is setup properly with the correct endpoint registration.':
    'Se ha identificado un punto final duplicado. Los EFC históricos pueden proceder de varios criterios de valoración. Se debe tener cuidado al revisar el historial de estos puntos finales. Compruebe que Harvester está configurado correctamente con el registro de punto final correcto.',
  'Duplicate Endpoint': 'Punto final duplicado',
  'Inventory': 'Inventario',
  'Entitlements': 'Derechos',
  'Green': 'Verde',
  'Yellow': 'Amarillo',
  'Red': 'Rojo',
  'Empty': 'Vacío',
  'Entitlement is connected and sending data as expected.':
    'La entidad está conectada y envía los datos como se espera.',
  'Entitlement is connected, but not sending data as expected.  Review the detailed message on the entitlement details page. Click on card to get details on how to resolve.':
    'La asignación está conectada, pero no envía los datos esperados.  Revise el mensaje detallado en la página de detalles del derecho. Haga clic en la tarjeta para obtener información sobre cómo resolver el problema.',
  'Entitlement is expected, but is not connected and no data.  Click on card to get details on how to resolve.':
    'Se espera la habilitación, pero no está conectada y no hay datos.  Haga clic en la tarjeta para obtener detalles sobre cómo resolver.',
  'Entitlement does not send information as to the current status. No action is required.':
    'La habilitación no envía información sobre el estado actual. No se requiere ninguna acción.',
  'Currently offline, endpoint will be removed when connected.':
    'Actualmente fuera de línea, el punto final se eliminará cuando se conecte.',
  'Currently offline, usertag will update when connected.':
    'Actualmente sin conexión, usertag se actualizará cuando se conecte.',
  'Disconnected': 'Desconectado',
  'A disconnected system was previously connected and sending data to ACDE. However, data transmission has now stopped, exceeding the expected timeframe for this connection. Please refer to the ‘Last Data’ field to determine when data was last received.':
    'Un sistema desconectado estaba previamente conectado y enviando datos a ACDE. Sin embargo, la transmisión de datos se ha detenido, superando el plazo previsto para esta conexión. Consulte el campo «Últimos datos» para determinar cuándo se recibieron los datos por última vez.',
  'Software Updated': 'Software actualizado',
  'Software': 'Software',
  'components updated': 'componentes actualizados',
  'component updated': 'componente actualizado',
  'Last Data': 'Últimos datos',
  'First Data': 'Primeros datos',
  'Data Origin': 'Origen de los datos',
  'Avg Data Size': 'Tamaño medio de los datos',
  'Transport': 'Transporte',
  'Version': 'Versión',
  'Last Update': 'Última actualización',
  'Filter by Software': 'Filtrar por software',
  'Filter by Version': 'Filtrar por versión',
  'Filter by Last Update': 'Filtrar por última actualización',
  'Please enter some text': 'Introduzca un texto',
  'Serial Number already exists.': 'El número de serie ya existe.',
  'Managed Services': 'Servicios gestionados',
  'True': 'Verdadero',
};

export default translation;
