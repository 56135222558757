class FailedNotifications {
  isNotificationRead: number;
  notificationId: string;
  lastActionTakenSync: string;
  constructor(
    isNotificationRead: number,
    notificationId: string,
    lastActionTakenSync: string
  ) {
    this.isNotificationRead = isNotificationRead;
    this.notificationId = notificationId;
    this.lastActionTakenSync = lastActionTakenSync;
  }
}

export default FailedNotifications;
