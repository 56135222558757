import { useApi } from '../../acfs-apis/dwar-api-provider';
import ManualEndPoint, {
  singleUserEquipment,
} from '../../Models/ManualEndPoint';

export const saveEquipmentSingleMachine = async (
  data: singleUserEquipment,
  api: ReturnType<typeof useApi>
) => {
  try {
    return await api.saveSingleUserEquipment(data);
  } catch (error) {
    console.log(error);
  }
};

export const deleteManualEndpoint = async (
  serialNumber: string,
  api: ReturnType<typeof useApi>
) => {
  return await api.deleteSavedUserEquipment(serialNumber);
};

export const creatSaveEquipmentsData = (
  updatedEquipments: ManualEndPoint[]
) => {
  return updatedEquipments?.map((equipment) => ({
    serialNumber: equipment.serialNumber,
    userTag: equipment?.userTag ?? '',
    isExternallyNavigated: equipment?.externallyNavigated ?? false,
  }));
};

export const saveUserEquipmentData = async (
  data: ManualEndPoint[],
  api: ReturnType<typeof useApi>
) => {
  const apirReadyData = creatSaveEquipmentsData(data);
  try {
    await api.saveUserEquipments(apirReadyData);
  } catch (error) {
    console.log(error);
  }
};
