import SoftwareInventory from '../../../Models/SoftwareInventory';
import { useState, useEffect } from 'react';
import CustomTable from '../../../components/custom-table';
import { useTranslation } from 'react-i18next';

interface Props {
  softwares: SoftwareInventory[];
}

interface ISoftwareDetails {
  Software: string;
  Version: string;
  LastUpdate: string;
}

function Software({ softwares }: Props) {
  const [softwareInfo, setSoftwareInfo] = useState<ISoftwareDetails[]>([]);
  const { t } = useTranslation();
  useEffect(() => {
    let formattedSoftwares: ISoftwareDetails[] = [];
    softwares.forEach((software) => {
      const lastUpdateDate = new Date(software.lastUpdate);
      const currentDate = new Date(Date.now());

      const diffInTime = currentDate.getTime() - lastUpdateDate.getTime();
      const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24));

      const hours =
        lastUpdateDate.getHours() < 10
          ? `0${lastUpdateDate.getHours()}`
          : lastUpdateDate.getHours().toString();
      const minutes =
        lastUpdateDate.getMinutes() < 10
          ? `0${lastUpdateDate.getMinutes()}`
          : lastUpdateDate.getMinutes().toString();

      const lastUpdate = software.lastUpdate
        ? `${diffInDays} days ago ${hours}:${minutes} ${
            lastUpdateDate
              .toDateString()
              .split(lastUpdateDate.getFullYear().toString())[0]
          }`
        : '';

      formattedSoftwares.push({
        Software: software.name,
        Version: software.version,
        LastUpdate: lastUpdate,
      });
    });

    setSoftwareInfo(formattedSoftwares);
  }, [softwares, t]);

  const hookOptions = {
    enableColumnFilters: true,
    enableSorting: true,
    initialState: {
      showColumnFilters: true,
    },
  };
  const HeaderDef = [
    {
      header: t('Software'),
      accessorKey: 'Software',
      muiFilterTextFieldProps: {
        placeholder: t('Filter by Software'),
      },
    },
    {
      header: t('Version'),
      accessorKey: 'Version',
      muiFilterTextFieldProps: {
        placeholder: t('Filter by Version'),
      },
    },
    {
      header: t('Last Update'),
      accessorKey: 'LastUpdate',
      muiFilterTextFieldProps: {
        placeholder: t('Filter by Last Update'),
      },
    },
  ];

  return (
    <div className="mb-2">
      <CustomTable
        data={softwareInfo}
        columnOptions={{
          size: 109,
        }}
        headerDef={HeaderDef}
        hookOptions={hookOptions}
      />
    </div>
  );
}
export default Software;
