import { useApi } from '../../acfs-apis/dwar-api-provider';
import ManualEndPoint, {
  IGetUserEquipments,
} from '../../Models/ManualEndPoint';
import { IS_ME_SYNC_DONE } from '../../constants';
import db from '../../index-db';
import { deleteManualEndpoint, saveUserEquipmentData } from './apis';

type syncManualEndpointOptions = ReturnType<typeof useApi>;

const syncUserEquipments = async (
  fetchedManualEndpointsInstance: ManualEndPoint[],
  localManualEndpointsInstance: ManualEndPoint[]
) => {
  let localManualEndpoints = [...localManualEndpointsInstance];

  let fetchedManualEndpoints = new Map(
    fetchedManualEndpointsInstance.map((endpoint) => [
      endpoint.serialNumber,
      endpoint,
    ])
  );

  let newIdentity: ManualEndPoint[] = [];

  for (const localEndpoint of localManualEndpoints) {
    const fetchedEndpoint = fetchedManualEndpoints.get(
      localEndpoint.serialNumber
    );
    if (fetchedEndpoint) {
      newIdentity.push(fetchedEndpoint);
    } else {
      newIdentity.push(localEndpoint);
    }
  }

  if (localManualEndpoints.length <= 0) {
    newIdentity = [...fetchedManualEndpointsInstance];
  }

  return newIdentity;
};
const deleteUpdateOfflineFailedEndpoints = async (
  api: syncManualEndpointOptions,
  isOnline: boolean
) => {
  const foundFailedManualEndpoints = await db.failedManualEndpoints.toArray();
  const allPromises: Promise<void | Response>[] = [];
  if (isOnline && foundFailedManualEndpoints.length) {
    foundFailedManualEndpoints.forEach(
      async ({ isDeleted, serialNumber, userTag }) => {
        if (isDeleted) {
          allPromises.push(
            deleteManualEndpoint(serialNumber, api).then(() =>
              db.failedManualEndpoints.delete(serialNumber)
            )
          );
        } else if (userTag) {
          allPromises.push(
            api
              .saveSingleUserEquipment({
                serialNumber,
                userTag,
              })
              .then(() => db.failedManualEndpoints.delete(serialNumber))
          );
        }
      }
    );
  }
  return await Promise.allSettled(allPromises);
};

const serverSyncFetchedEndpoints = async (api: syncManualEndpointOptions) => {
  const serverSavedEndpointLists: IGetUserEquipments[] =
    await api.getUserEquipments();
  const serverManualEquipmentMap = new Map();
  const endpointSerialNumbers = serverSavedEndpointLists.map((endpoint) => {
    serverManualEquipmentMap.set(endpoint.serialNumber, [
      endpoint.userTag,
      endpoint.isExternallyNavigated,
    ]);
    return endpoint.serialNumber;
  });
  const fetchedManualEndpoints: ManualEndPoint[] = (
    await (
      await api.getEndpointSummariesBySerialNumbers(endpointSerialNumbers)
    ).json()
  ).map((endpoint: ManualEndPoint) => ({
    ...endpoint,
    userTag: serverManualEquipmentMap.get(endpoint.serialNumber)[0],
    externallyNavigated: serverManualEquipmentMap.get(endpoint.serialNumber)[1],
  }));
  return fetchedManualEndpoints;
};

const syncManualEndpoints = async (
  api: syncManualEndpointOptions,
  isOnline: boolean
) => {
  await deleteUpdateOfflineFailedEndpoints(api, isOnline);
  const isFirstTimeSyncDone = localStorage.getItem(IS_ME_SYNC_DONE) === 'true';
  const fetchedManualEndpoints = await serverSyncFetchedEndpoints(api);

  const localManualEndpoints = await db.manualEndPoints.toArray();
  const finalizedEntry = !isFirstTimeSyncDone
    ? await syncUserEquipments(fetchedManualEndpoints, localManualEndpoints)
    : fetchedManualEndpoints;

  !isFirstTimeSyncDone && (await saveUserEquipmentData(finalizedEntry, api));

  localStorage.setItem(IS_ME_SYNC_DONE, 'true');
  await db.manualEndPoints.clear();
  await db.manualEndPoints.bulkAdd(finalizedEntry);
  return finalizedEntry.sort();
};

export default syncManualEndpoints;
