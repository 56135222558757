import Insight from './Insight';

export type singleUserEquipment = {
  serialNumber: string;
  userTag?: string;
  isExternallyNavigated?: boolean;
};
export type saveEquipments = singleUserEquipment[] | [];

export interface IManualEndPoint {
  id: string;
  serialNumber: string;
  prodDesc: string;
  terminalId: string;
  customerName: string;
  serviceInsights: Insight[];
  preventiveInsights: Insight[];
  userTag?: string;
  chronicDetails?: string;
  generatedSummary: string;
  chronicIndicator: string;
  externallyNavigated?: boolean;
  connected?: boolean;
  model?: string;
}
export interface IGetUserEquipments {
  serialNumber: string;
  userTag: string;
  isExternallyNavigated: boolean;
}
export class ManualEndPoint {
  id: string;
  serialNumber: string;
  prodDesc: string;
  terminalId: string;
  customerName: string;
  serviceInsights: Insight[];
  preventiveInsights: Insight[];
  userTag?: string;
  chronicDetails?: string;
  generatedSummary?: string;
  chronicIndicator: string;
  externallyNavigated?: boolean;
  connected?: boolean;
  model?: string;

  constructor({
    id,
    serialNumber,
    prodDesc,
    terminalId,
    customerName,
    preventiveInsights,
    serviceInsights,
    chronicDetails,
    generatedSummary,
    chronicIndicator,
    userTag,
    externallyNavigated,
    connected,
    model,
  }: IManualEndPoint) {
    this.id = id;
    this.serialNumber = serialNumber;
    this.prodDesc = prodDesc;
    this.terminalId = terminalId;
    this.customerName = customerName;
    this.serviceInsights = serviceInsights;
    this.preventiveInsights = preventiveInsights;
    this.userTag = userTag;
    this.chronicDetails = chronicDetails;
    this.generatedSummary = generatedSummary;
    this.chronicIndicator = chronicIndicator;
    this.externallyNavigated = externallyNavigated;
    this.connected = connected;
    this.model = model;
  }
}

export default ManualEndPoint;
