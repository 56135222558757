export enum LogType {
  App = 'App',
  Page = 'Page',
  Home = 'Home',
  EFC = 'EFC',
  Equipment = 'Equipment',
  EquipmentDetails = 'Equipment Details',
  EquipmentHistory = 'Equipment History',
  Notes = 'Notes',
  Fb = 'Fb',
  Info = 'Info',
  Me = 'Me',
  Part = 'Part',
  ExternalSource = 'ExternalSource',
  FbType = 'FbType',
  Value = 'Value',
  Insight = 'Insight',
  FeedBack = 'Fb',
  ViewFeedback = 'ViewFeedback',
  Help = 'Help',
  Menu = 'Menu',
  Product = 'Product',
  RecentlyViewed = 'RecentlyViewed',
  SavedItems = 'SavedItems',
  ModuleAbbreviation = 'ModuleAbbreviation',
  ModuleResults = 'ModuleResults',
  KeyWord = 'KeyWord',
  Search = 'Search',
  Settings = 'Settings',
  ServiceId = 'ServiceId',
  Comment = 'Comment',
  RepairAction = 'RA',
  Language = 'Lang',
  DSAFeedbackDlStatus = 'DSAFeedbackDlStatus',
  DSAFeedbackDlTimeMs = 'DSAFeedbackDlTimeMs',
  PartsDlStatus = 'PartsDlStatus',
  PartsDlTimeMs = 'PartsDlTimeMs',
  DsaDlStatus = 'DsaDlStatus',
  DsaDlTimeMs = 'DsaDlTimeMs',
  BuildVer = 'BuildVer',
  EndpointId = 'EndpointId',
  EfcCode = 'EfcCode',
  EfcRaCount = 'EfcRaCount',
  ProductId = 'ProductId',
  RaId = 'RAId',
  ServiceTaskId = 'ServiceTaskId',
  PartNumber = 'PartNumber',
  PartName = 'PartName',
  CorrectiveActionRequired = 'CorrectiveActionRequired',
  OpenTasks = 'OpenTasks',
  FeedBackComments = 'FeedBackComments',
  EFCSearch = 'EFCSearch',
  RepairActionPerformed = 'RepairActionPerformed',
  Title = 'Title',
  Details = 'CustomRepairActionDetails',
  NeedsReview = 'NeedsReview',
  TimeToFix = 'TimeToFix',
  SerialNumber = 'SerialNumber',
  QREFCSearch = 'QREFCSearch',
  Observations = 'Observ',
  AiSummary = 'AiSummary',
  RecommendedParts = 'RecommendedParts',
  EntDetails = 'EntDetails',
  Entitlement = 'ENT',
  NotificationList = 'NotificationList',
}
