import JSZip from 'jszip';
import db from '../index-db';
import { AuthContextProps } from 'react-oidc-context';
import Feedback from '../Models/Feedback';
import DSAFeedback from '../Models/DSAFeedback';
import CallToActionSettings from '../Models/CallToActionSettings';
import { saveEquipments, singleUserEquipment } from '../Models/ManualEndPoint';
import { saveNotification } from '../Models/Notifications';

interface Args {
  auth: AuthContextProps;
  getRequestHeaders: (language?: string) => HeadersInit;
  isPartner: boolean;
  accessToken: string | undefined;
  localUserIdOrGroup: string;
}

const APIS = (args: Args) => {
  const {
    auth,
    getRequestHeaders,
    isPartner,
    accessToken,
    localUserIdOrGroup,
  } = args;
  const allSettledPolyFill = async (params: Promise<void>[]): Promise<void> => {
    Promise.allSettled =
      Promise.allSettled ||
      ((promises: any[]) =>
        Promise.all(
          promises.map((p: Promise<any>) =>
            p
              .then((value: any) => ({
                status: 'fulfilled',
                value,
              }))
              .catch((reason: any) => ({
                status: 'rejected',
                reason,
              }))
          )
        ));
    await Promise.allSettled(params);
  };
  const apis = {
    fetchImage: async (imageId: string | number, imageType: string) => {
      const response = await fetch(
        `/dwar/api/knowledgebase/MultiMedia/image/${imageType}/${imageId}`,
        {
          method: 'get',
          headers: getRequestHeaders(),
        }
      );
      if (response.status === 200 || response.status === 0) {
        const imageBlob = await response.blob();
        return URL.createObjectURL(imageBlob);
      } else {
        throw new Error(response.statusText);
      }
    },

    fetchContent: async (
      language: string,
      signal?: AbortSignal | null
    ): Promise<{
      dsaStatus: string;
      dsaDownloadTime: number;
      partsStatus: string;
      partsDownloadTime: number;
      dsaFeedbackStatus: string;
      dsaFeedbackDownloadTime: number;
    }> => {
      const fetchDSAContent = async (
        contentmap: Map<string, any>
      ): Promise<{ statusText: string; downloadTime: number }> => {
        const startTime = performance.now();
        const response = await fetch(
          '/dwar/api/knowledgebase/DsaData/getdsabundle',
          {
            method: 'get',
            headers: getRequestHeaders(language),
            signal,
          }
        );
        if (response.status === 200 || response.status === 0) {
          const data = await response.arrayBuffer();
          const contents = await JSZip.loadAsync(data);
          const promises = Object.keys(contents.files).map(async (f) => {
            const content = await contents.files[f].async('string');
            contentmap.set(f, JSON.parse(content));
          });
          await allSettledPolyFill(promises);
          const endTime = performance.now();
          return {
            statusText: response.statusText,
            downloadTime: Math.round(endTime - startTime),
          };
        } else {
          return { statusText: response.statusText, downloadTime: 0 };
        }
      };

      const fetchPartsContent = async (
        contentmap: Map<string, any>
      ): Promise<{ statusText: string; downloadTime: number }> => {
        const startTime = performance.now();
        const response = await fetch(
          '/dwar/api/knowledgebase/Part/getpartbundle',
          {
            method: 'get',
            headers: getRequestHeaders(language),
            signal,
          }
        );
        if (response.status === 200 || response.status === 0) {
          const data = await response.arrayBuffer();
          const contents = await JSZip.loadAsync(data);
          const promises = Object.keys(contents.files).map(async (f) => {
            if (
              contents.files[f].name === 'Parts.json' ||
              contents.files[f].name === 'Products.json'
            ) {
              const content = await contents.files[f].async('string');
              contentmap.set(f, JSON.parse(content));
            }
          });
          await allSettledPolyFill(promises);
          const endTime = performance.now();
          return {
            statusText: response.statusText,
            downloadTime: Math.round(endTime - startTime),
          };
        } else {
          return { statusText: response.statusText, downloadTime: 0 };
        }
      };

      const fetchDSAFeedback = async (
        contentmap: Map<string, any>
      ): Promise<{ statusText: string; downloadTime: number }> => {
        try {
          const startTime = performance.now();
          const feedbackData = (await apis.fetchJson(
            '/dwar/api/knowledgebase/Feedback/GetFeedback',
            { signal }
          )) as DSAFeedback[];
          contentmap.set('feedback.json', feedbackData);
          const endTime = performance.now();
          return {
            statusText: 'DSA Feedback downloaded successfully',
            downloadTime: Math.round(endTime - startTime),
          };
        } catch (error) {
          return {
            statusText: `DSA Feedback downloaded failed. ${error}`,
            downloadTime: 0,
          };
        }
      };

      const contentmap = new Map<string, any>();
      const dsaResult = await fetchDSAContent(contentmap);
      let partsResult = {
        statusText: 'Parts download not accessible to user.',
        downloadTime: 0,
      };

      if (!isPartner) {
        partsResult = await fetchPartsContent(contentmap);
      }

      const dsaFeedbackResult = await fetchDSAFeedback(contentmap);

      await db.load(contentmap);

      return {
        dsaStatus: dsaResult.statusText,
        dsaDownloadTime: dsaResult.downloadTime,
        partsStatus: partsResult.statusText,
        partsDownloadTime: partsResult.downloadTime,
        dsaFeedbackStatus: dsaFeedbackResult.statusText,
        dsaFeedbackDownloadTime: dsaFeedbackResult.downloadTime,
      };
    },

    fetchJson: async (
      resource: RequestInfo | URL,
      options?: RequestInit,
      langCode: string = 'en'
    ) => {
      if (auth.user?.expired) {
        auth.signinSilent();
      }
      const response = await fetch(resource, {
        ...options,
        headers: {
          ...getRequestHeaders(langCode),
          ...options?.headers,
        },
      });

      if (!response.ok) {
        throw Error(`Response status ${response.status}`);
      }

      return await response.json();
    },

    deleteJson: async (resource: RequestInfo | URL) => {
      if (auth.user?.expired) {
        auth.signinSilent();
      }
      const response = await fetch(resource, {
        method: 'DELETE',
        headers: getRequestHeaders(),
      });
      if (!response.ok) {
        throw Error(`Response status ${response.status}`);
      } else {
        return response;
      }
    },

    postFormData: async (
      resource: RequestInfo | URL,
      feedback: Feedback,
      isiOS: boolean
    ) => {
      const formData = new FormData();
      Object.entries(feedback).forEach(([key, value]) => {
        if (key === 'imageData') {
          if (isiOS === false) {
            value.forEach((f: File) => {
              formData.append('imageData', f);
            });
            feedback.imageBuffer = [];
          }
        } else if (key === 'imageBuffer') {
          if (isiOS === true) {
            value.forEach(async (f: any) => {
              const imageFile = new Blob([f], { type: 'image/jpeg' });
              formData.append('imageData', imageFile);
            });
            feedback.imageData = [];
          }
        } else {
          formData.append(key, value);
        }
      });

      let addTofeedbackFailed: boolean = false;
      const response = await fetch(resource, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }).catch(() => {
        addTofeedbackFailed = true;
      });
      if (!response?.ok || addTofeedbackFailed) {
        db.transaction('rw', db.failedFeedback, async () => {
          await db.failedFeedback
            .orderBy('id')
            .last()
            .then(async (lastItem) => {
              let lastId = lastItem?.id ?? 1;
              await db.failedFeedback.add({ id: ++lastId, feedback: feedback });
            });
        });
        throw Error(`Response status ${response?.status}`);
      } else {
        return true;
      }
    },

    fetchOIDCConfig: async () => {
      const oidcConfigFile = 'oidcconfig.json';

      const result = await fetch(oidcConfigFile);
      if (result.status === 200) {
        return await result.json();
      } else {
        throw Error(
          `Unable to load ${oidcConfigFile}, HTTP status ${result.status}`
        );
      }
    },

    postJson: async (
      resource: RequestInfo | URL,
      reqBody: string,
      langCode: string = 'en'
    ) => {
      if (auth.user?.expired) {
        auth.signinSilent();
      }
      const headers = new Headers({
        'Authorization': `Bearer ${accessToken}`,
        'content-type': 'application/json',
        ...getRequestHeaders(langCode),
        'DBD-UserGroupOrId': localUserIdOrGroup,
      });
      const response = await fetch(resource, {
        method: 'POST',
        headers: headers,
        body: reqBody,
      });

      if (!response.ok) {
        throw Error(`Response status ${response.status}`);
      } else {
        return response;
      }
    },

    fetchCallToActionSettings: async (
      endpointId: string,
      taskNumber: string | undefined,
      langCode: string = 'en'
    ): Promise<CallToActionSettings[]> => {
      taskNumber = taskNumber ?? '000000';
      return (await apis.fetchJson(
        `/dwar/api/acfs/CallToAction/getUserCallToActions/?endPointId=${endpointId}&taskNumber=${taskNumber}`,
        undefined,
        langCode
      )) as CallToActionSettings[];
    },

    saveCallToActionSettings: async (
      callToActionSettings: Omit<CallToActionSettings, 'id'>
    ) => {
      return await apis.postJson(
        '/dwar/api/acfs/CallToAction/saveUserCallToAction',
        JSON.stringify(callToActionSettings)
      );
    },
    getEndpointById: async (endpointId: string) => {
      return await apis.fetchJson(
        `/dwar/api/almanac/ServiceEndpoint/GetEndpointById?endpointId=${endpointId}`
      );
    },
    getEndpointBySerialNumber: async (serialNumber: string) => {
      return await apis.fetchJson(
        `/dwar/api/almanac/ServiceEndpoint/GetEndpointBySerialNumber?serialNumber=${serialNumber}`
      );
    },
    getEndpointSummaryById: async (endpointId: string) => {
      return await apis.fetchJson(
        `/dwar/api/almanac/ServiceEndpoint/GetEndpointSummaryById?endpointId=${endpointId}`
      );
    },

    getEndpointSummaryBySerialNumber: async (serialNumber: string) => {
      return await apis.fetchJson(
        `/dwar/api/almanac/ServiceEndpoint/GetEndpointSummaryBySerialNumber?serialNumber=${serialNumber}`
      );
    },
    getEndpointSummariesByIds: async (endpointIds: string[]) => {
      return await apis.postJson(
        `/dwar/api/almanac/ServiceEndpoint/GetEndpointSummariesByIds`,
        JSON.stringify(endpointIds)
      );
    },
    getEndpointSummariesBySerialNumbers: async (serialNumbers: string[]) => {
      return await apis.postJson(
        `/dwar/api/almanac/ServiceEndpoint/GetEndpointSummariesBySerialNumbers`,
        JSON.stringify(serialNumbers)
      );
    },

    saveUserEquipments: async (data: saveEquipments) => {
      return await apis.postJson(
        `/dwar/api/acfs/UserEquipment/saveUserEquipments`,
        JSON.stringify(data)
      );
    },
    saveSingleUserEquipment: async (data: singleUserEquipment) => {
      return await apis.postJson(
        `/dwar/api/acfs/UserEquipment/saveUserEquipment`,
        JSON.stringify(data)
      );
    },

    getUserEquipments: async () => {
      return apis.fetchJson('/dwar/api/acfs/UserEquipment/getUserEquipments');
    },

    deleteSavedUserEquipment: async (serialNumber: string) => {
      return apis.deleteJson(
        `/dwar/api/acfs/UserEquipment/deleteUserEquipment?serialNumber=${serialNumber}`
      );
    },
    saveMultipleNotifications: async (notificationsArr: saveNotification[]) => {
      return await apis.postJson(
        `/dwar/api/acfs/Notification/saveNotifications`,
        JSON.stringify(notificationsArr)
      );
    },
    saveSingleNotification: async (notification: saveNotification) => {
      return await apis.postJson(
        `/dwar/api/acfs/Notification/saveNotification`,
        JSON.stringify(notification)
      );
    },
  };

  return apis;
};

export default APIS;
